import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';

import i18nextConfig from './config';
import ReduxDetector from './reduxDetector';

type Props = {
  redux: {
    lookupRedux: () => void;
    cacheUserLanguageRedux?: (language: string) => void;
  };
};

const Detector = new LanguageDetector();
Detector.addDetector(ReduxDetector);

const configureI18n = ({ redux }: Props) => {
  if (!i18n.isInitialized) {
    i18n
      .use(Backend)
      .use(Detector)
      .use(initReactI18next)
      .init({
        fallbackLng: i18nextConfig.fallbackLng,
        ns: i18nextConfig.ns,
        debug: process.env.NODE_ENV !== 'production',
        backend: {
          backends: [HttpApi],
          backendOptions: [
            {
              loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
            },
          ],
        },
        detection: {
          order: ['redux'],
          lookupRedux: redux.lookupRedux,
          cacheUserLanguageRedux: redux.cacheUserLanguageRedux,
          caches: ['redux'],
          excludeCacheFor: ['cimode'],
        },
        interpolation: {
          escapeValue: false,
        },
      });
  }
};

export default configureI18n;
