import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import {
  ReduxRouter,
  ReduxRouterSelector,
} from '@lagunovsky/redux-react-router';

import i18nextConfig from 'src/client/i18n/config';
import configureI18n from 'src/client/i18n';

import configureStore, { history } from 'src/client/store/configureStore';
import { setLanguage } from 'src/client/store/i18next/actions';

import ThemeProvider from 'src/client/contexts/ThemeContext/ThemeContext';

import '@fancyapps/ui/dist/fancybox/fancybox.css';
import './index.scss';

import App from 'src/client/App';

import * as serviceWorker from './serviceWorker';

const { store, persistor } = configureStore({
  i18next: i18nextConfig,
});

export type RootState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;

const routerSelector: ReduxRouterSelector<RootState> = (state: RootState) =>
  state.router;

const i18n = configureI18n({
  redux: {
    lookupRedux: () => store.getState().i18next,
    cacheUserLanguageRedux: (language: string) =>
      store.dispatch(setLanguage(language)),
  },
});

const BaseApp: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider>
          <ReduxRouter history={history} routerSelector={routerSelector}>
            <App history={history} />
          </ReduxRouter>
        </ThemeProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

const app = document.getElementById('root') as HTMLElement;

hydrateRoot(app, <BaseApp />);

serviceWorker.register();
