import { GeolocationAction, GeolocationState } from './types';
import { SET_GEOLOCATION, SET_GEOLOCATION_ERROR } from './constants';

const initialState: GeolocationState = {
  data: {
    ip: '',
    hostname: '',
    org: '',
    country: '',
    region: '',
    city: '',
    postal: '',
    loc: '',
    timezone: '',
  },
  error: '',
};

export default (
  state: GeolocationState = initialState,
  action: GeolocationAction,
): GeolocationState => {
  switch (action.type) {
    case SET_GEOLOCATION:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case SET_GEOLOCATION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
