import React, { useEffect } from 'react';
import ReactGA4 from 'react-ga4';

import { Location } from 'history';

import { GoogleAnalyticsProps } from 'src/client/interfaces/googleAnalytics.interface';

const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({
  location,
}: GoogleAnalyticsProps) => {
  const logPageChange = (loc: Location) => {
    const page = loc.pathname + loc.search + loc.hash;

    ReactGA4.send({ hitType: 'pageview', page: page });
  };

  useEffect(() => {
    logPageChange(location);

    return () => logPageChange(location);
  }, [location]);

  return null;
};

const RouteTracker = ({ location }: GoogleAnalyticsProps) => (
  <GoogleAnalytics location={location} />
);

const init = () => {
  const isGAEnabled: boolean = process.env.NODE_ENV === 'production';

  if (isGAEnabled) {
    ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_TRACKING_ID);
  }

  return true;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
};
