import React from 'react';
import { Helmet } from 'react-helmet';

import { PageTitleProps } from 'src/client/interfaces/pageTitle.interface';

const PageTitle: React.FC<PageTitleProps> = ({
  title = '',
}: PageTitleProps) => (
  <Helmet>
    <title>{title && `${title} - `}SignCoders</title>
  </Helmet>
);

export default PageTitle;
