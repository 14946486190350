import React from 'react';
import { useTranslation } from 'react-i18next';

import Slideshow from 'src/client/components/Slideshow/Slideshow';

const Intro: React.FC = () => {
  const { t, i18n } = useTranslation('intro');

  return (
    <section id="intro">
      <div className="container">
        <h1 className="mb-8 max-w-[900px] text-4xl leading-[64px] md:text-6xl md:leading-[90px] lg:text-7xl lg:leading-[108px]">
          {t('title.0')} <span className="text-green-400">{t('title.1')}</span>{' '}
          {t('title.2')} <span className="text-green-400">{t('title.3')}</span>
          {i18n.language === 'hu' ? '' : ' '}
          {t('title.4')}
        </h1>
        <p className="mb-8 max-w-5xl text-lg leading-7 md:text-xl md:leading-9">
          {t('textOne')}
        </p>
        <div className="mb-12">
          <Slideshow />
        </div>
        <p className="mb-20 max-w-5xl text-lg leading-7 md:text-xl md:leading-9">
          {t('textTwo')}
        </p>
      </div>
    </section>
  );
};

export default Intro;
