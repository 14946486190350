import {
  Action,
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
  Reducer,
  Store,
  StoreEnhancer,
} from 'redux';
import {
  createRouterMiddleware,
  createRouterReducer,
} from '@lagunovsky/redux-react-router';
import { thunk, ThunkMiddleware } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistConfig, Persistor } from 'redux-persist/es/types';
import { createBrowserHistory, createMemoryHistory, History } from 'history';

import { isServer } from 'src/client/helpers/globals';
import storage from 'src/client/helpers/storage';

import i18nextReducer from './i18next/reducer';
import themeReducer from './theme/reducer';
import contactReduder from './contact/reducer';
import geolocationReducer from './geolocation/reducer';
import postReducer from './post/reducer';
import postsReducer from './posts/reducer';
import searchFilterReducer from './searchFilter/reducer';

export const history: History = isServer
  ? createMemoryHistory()
  : createBrowserHistory();

let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }
}

const middlewares: ThunkMiddleware<any, Action, undefined>[] = [
  createRouterMiddleware(history),
  thunk,
];

const enhancers: StoreEnhancer = composeEnhancers(
  applyMiddleware(...middlewares),
);

const persistConfig: PersistConfig<any> = {
  key: 'signcoders',
  storage,
  blacklist: [
    'router',
    'contact',
    'geolocation',
    'post',
    'posts',
    'searchFilter',
  ],
};

const rootReducer: Reducer = combineReducers({
  router: createRouterReducer(history),
  i18next: i18nextReducer,
  theme: themeReducer,
  contact: contactReduder,
  geolocation: geolocationReducer,
  post: postReducer,
  posts: postsReducer,
  searchFilter: searchFilterReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (preloadedState: any) => {
  const store: Store = createStore(persistedReducer, preloadedState, enhancers);
  const persistor: Persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
