import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  GrantFileProps,
  GrantObjectiveProps,
  GrantProps,
} from 'src/client/types/grant-objectives';
import { ImageProps } from 'src/client/types/image';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import { history } from 'src/client/store/configureStore';

import Contact from 'src/client/components/Contact/Contact';
import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';
import LandingIntro from 'src/client/components/LandingIntro/LandingIntro';
import PageTitle from 'src/client/components/PageTitle/PageTitle';

import './DigitalKnowledgeDissemination.scss';

const grantObjectives: GrantObjectiveProps[] = [
  {
    title: 'DigInclude',
    imageSrc: 'digInclude.webp',
    projectReference: '2021-2-HU01-KA210-ADU-000049819',
    partners: [
      {
        src: 'hekate-foundation.webp',
        title: 'Hekate Foundation',
        url: 'https://hekate.foundation',
      },
    ],
  },
  {
    title: 'Financial Signs',
    imageSrc: 'financial-signs.webp',
    websiteUrl: 'https://www.financialsigns.eu',
    projectReference: '2022-2-AT01-KA220-YOU-000101712',
    partners: [
      {
        src: 'equalizent.webp',
        title: 'Equalizent',
        url: 'https://equalizent.eu',
      },
      {
        src: 'istituto-dei-sordi-di-torino.webp',
        title: 'Istituto dei Sordi di Torino',
        url: 'https://istitutosorditorino.org',
      },
      {
        src: 'innosign.webp',
        title: 'Innosign',
        url: 'https://www.innosign.eu',
      },
    ],
  },
  {
    title: 'IDEAL Health in Deaf',
    imageSrc: 'ideal-health.webp',
    websiteUrl: 'https://ideal-health.top',
    projectReference: '2022-2-SK01-KA220-ADU-000102228',
    partners: [
      {
        src: '',
        title: 'A.U.S.R.U.',
        url: 'http://ausru.pgcesvol.com/',
      },
      {
        src: 'cnse.webp',
        title: 'Confederación Estatal de Personas Sordas',
        url: 'https://www.cnse.es',
      },
      {
        src: 'innosign.webp',
        title: 'Innosign',
        url: 'https://www.innosign.eu',
      },
      {
        src: 'institut-pro-regionalni-rozvoj-o.p.s.webp',
        title: 'Institut pro regionální rozvoj, o.p.s.',
        url: 'https://iregio.org',
      },
      {
        src: 'masarykova-univerzita.webp',
        title: 'Masarykova univerzita',
        url: 'https://www.muni.cz',
      },
    ],
  },
  {
    title: 'Deaf Climate ACTion',
    imageSrc: 'deaf-climate-action.webp',
    websiteUrl: 'https://deafclimateaction.eu',
    projectReference: '2023-1-AT01-KA220-YOU-000161249',
    partners: [
      {
        src: 'deafstudio.webp',
        title: 'DeafStudio',
        url: 'https://deafstudio.net/',
      },
      {
        src: 'eudy.webp',
        title: 'European Union of the Deaf Youth',
        url: 'https://eudy.info/',
      },
      {
        src: 'turkoois.webp',
        title: 'Turkoois',
        url: 'https://turkoois.eu/',
      },
    ],
  },
];

const DigitalKnowledgeDissemination: React.FC = () => {
  const { t } = useTranslation(['global', 'digital-knowledge-dissemination']);

  const { hash } = history.location;

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1) });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="digital-knowledge-dissemination">
      <PageTitle
        title={t('pageTitle', { ns: 'digital-knowledge-dissemination' })}
      />
      <LandingIntro
        title={t('title', { ns: 'digital-knowledge-dissemination' })}
        backgroundImageSrc="digital-knowledge-dissemination-cover.webp"
        illustrationImageSrc="digital-knowledge-dissemination-illustration.webp"
      />
      <div className="bg-right-side bg-right-top">
        <section id="our-applications" className="pt-20">
          <div className="container">
            <h2>
              {t('ourApplications', { ns: 'digital-knowledge-dissemination' })}
            </h2>
            {t('grants', {
              ns: 'digital-knowledge-dissemination',
              returnObjects: true,
            }).map((grant: GrantProps, index: number) => (
              <FadeInAnimation key={index} direction="down">
                <div className="card rounded-3xl custom-shadow-hard">
                  <div className="flex flex-col md:flex-row justify-between">
                    <div>
                      <div className="mb-8">
                        {grantObjectives[index].websiteUrl ? (
                          <a
                            href={grantObjectives[index].websiteUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {grantObjectives[index].imageSrc && (
                              <img
                                src={
                                  grantObjectives[index].imageSrc
                                    ? require(
                                        'src/client/assets/images/' +
                                          grantObjectives[index].imageSrc,
                                      )
                                    : ''
                                }
                                alt={grant.title}
                                className="w-full max-w-[250px] max-h-[125px]"
                              />
                            )}
                          </a>
                        ) : (
                          <>
                            {grantObjectives[index].imageSrc && (
                              <img
                                src={
                                  grantObjectives[index].imageSrc
                                    ? require(
                                        'src/client/assets/images/' +
                                          grantObjectives[index].imageSrc,
                                      )
                                    : ''
                                }
                                alt={grant.title}
                                className="w-full max-w-[250px] max-h-[125px]"
                              />
                            )}
                          </>
                        )}
                      </div>
                      <h3 className="text-green-400 uppercase">
                        {grant.title}
                      </h3>
                      {grant.texts &&
                        grant.texts.map((text: any, i: number) => (
                          <Fragment key={i}>
                            {text.lists ? (
                              <ul className="mb-4 pl-5 text-white list-disc">
                                {text.lists.map((list: string, j: number) => (
                                  <li key={j}>{list}</li>
                                ))}
                              </ul>
                            ) : (
                              <p className="mb-4 text-white">{text}</p>
                            )}
                          </Fragment>
                        ))}
                    </div>
                    {grantObjectives[index].partners.length > 0 && (
                      <div className="flex flex-col md:flex-row md:flex-[0_0_35%] lg:flex-[0_0_25%]">
                        <div className="mx-auto md:mx-8 my-8 md:my-auto w-[200px] md:w-0 h-0 md:h-[250px] border-2 border-solid border-green-400"></div>
                        <div>
                          <div className="mb-8 text-white text-lg font-medium">
                            {t('partners', {
                              ns: 'digital-knowledge-dissemination',
                            })}
                            :
                          </div>
                          {grantObjectives[index].partners.map(
                            (partner: ImageProps, i: number) => (
                              <div key={i} className="mb-8 text-center">
                                {partner.url ? (
                                  <a
                                    href={partner.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {partner.src ? (
                                      <img
                                        src={
                                          partner.src
                                            ? require(
                                                'src/client/assets/images/' +
                                                  partner.src,
                                              )
                                            : ''
                                        }
                                        alt={partner.title}
                                        className="w-full max-w-[250px] max-h-[125px]"
                                      />
                                    ) : (
                                      <div className="text-xl">
                                        {partner.title}
                                      </div>
                                    )}
                                  </a>
                                ) : (
                                  <>
                                    {partner.src ? (
                                      <img
                                        src={
                                          partner.src
                                            ? require(
                                                'src/client/assets/images/' +
                                                  partner.src,
                                              )
                                            : ''
                                        }
                                        alt={partner.title}
                                        className="w-full max-w-[250px] max-h-[125px]"
                                      />
                                    ) : (
                                      <div className="text-xl">
                                        {partner.title}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {grant.pdfs && (
                    <>
                      <hr className="mt-8 mb-4" />
                      <h4 className="mb-4 text-white text-base leading-6 md:text-lg md:leading-7 uppercase">
                        {t('downloadableMaterials', {
                          ns: 'digital-knowledge-dissemination',
                        })}
                      </h4>
                      <ul className="pl-5 list-disc">
                        {grant.pdfs.map((pdf: GrantFileProps, i: number) => (
                          <li key={i} className="mb-4">
                            <a
                              href={require(
                                'src/client/assets/pdfs/' + pdf.file,
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex flex-row items-center"
                            >
                              {pdf.title}
                              <img
                                src={require('src/client/assets/logos/pdf.svg')}
                                alt={pdf.title}
                                className="ml-2 w-full max-w-[40px] lg:max-w-[40px]"
                              />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {grantObjectives[index].projectReference && (
                    <>
                      <hr className="mt-8 mb-4" />
                      <div className="text-white text-sm text-right">
                        <span className="font-bold">
                          {t('projectReference', {
                            ns: 'digital-knowledge-dissemination',
                          })}
                          :
                        </span>{' '}
                        {grantObjectives[index].projectReference}
                      </div>
                    </>
                  )}
                </div>
              </FadeInAnimation>
            ))}
            <FadeInAnimation
              direction="down"
              className="mx-auto w-[250px] lg:w-[400px] h-[150px] lg:h-[270px]"
            >
              <div className="flex flex-col justify-center items-center w-full h-full text-white text-base lg:text-xl bg-green text-center rounded-3xl whitespace-pre-line">
                {t('hereCouldBeOurApplicationTogether')}
                <Link
                  to="#contact"
                  className="mt-2 lg:mt-4 px-4 md:px-8 py-2 text-green-400 bg-white opacity-80 custom-shadow-soft"
                  onClick={() => {
                    scrollToElement({ elementId: 'contact', top: 0 });
                  }}
                >
                  {t('contactUs')}
                </Link>
              </div>
            </FadeInAnimation>
          </div>
        </section>
      </div>
      <Contact />
    </div>
  );
};

export default DigitalKnowledgeDissemination;
