import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReferenceProps } from 'src/client/interfaces/reference.interface';

import { ImageProps } from 'src/client/types/image';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import { history } from 'src/client/store/configureStore';

import LandingIntro from 'src/client/components/LandingIntro/LandingIntro';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import References from 'src/client/components/References/References';
import Stacks from 'src/client/components/Stacks/Stacks';
import Contact from 'src/client/components/Contact/Contact';

const references: ReferenceProps[] = [
  {
    src: 'yozz.webp',
    title: 'Yozz.eu',
    logo: 'yozz-logo.webp',
  },
  {
    src: 'wasabi.webp',
    title: 'Wasabi',
    logo: 'wasabi-logo.webp',
  },
  {
    src: 'parkett-zona.webp',
    title: 'Parkett Zóna',
    logo: 'parkett-zona-logo.webp',
  },
  {
    src: 'halandor-panzio.webp',
    title: 'Halandor Panzió',
    logo: 'halandor-panzio-logo.webp',
  },
  {
    src: 'puklusvin-kft.webp',
    title: 'Puklusvin Kft.',
    logo: 'puklusvin-kft-logo.webp',
  },
];

const stacks: ImageProps[] = [
  {
    src: 'woocommerce.svg',
    title: 'WooCommerce',
  },
  {
    src: 'opencart.svg',
    title: 'OpenCart',
  },
];

const Webshop: React.FC = () => {
  const { t } = useTranslation(['global', 'webshop']);

  const { hash } = history.location;

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1) });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="webshop">
      <PageTitle title={t('pageTitle', { ns: 'webshop' })} />
      <LandingIntro
        title={t('title', { ns: 'webshop' })}
        paragraph={t('checkOurReferencesAndLetsWorkTogether', { ns: 'global' })}
        backgroundImageSrc="webshop-cover.webp"
        illustrationImageSrc="webshop-illustration.webp"
      />
      <div className="bg-right-side bg-right-top">
        <References references={references} />
        <Stacks title={t('stack')} stacks={stacks} />
      </div>
      <Contact />
    </div>
  );
};

export default Webshop;
