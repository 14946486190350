import React, { useEffect, useRef, useState } from 'react';

import { FadeInAnimationProps } from 'src/client/interfaces/fadeInAnimation.interface';

import './FadeInAnimation.scss';

const FadeInAnimation: React.FC<FadeInAnimationProps> = ({
  direction = null,
  className = '',
  id = '',
  children,
}: FadeInAnimationProps) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => setVisible(entry.isIntersecting)),
    );

    if (domRef && domRef.current) observer.observe(domRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={domRef}
      className={`fade ${direction ? 'fade-' + direction : ''} ${
        isVisible ? 'is-visible' : ''
      } ${className}`}
      id={`${id}`}
    >
      {children}
    </div>
  );
};

export default FadeInAnimation;
