import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { MemberProps } from 'src/client/interfaces/member.interface';

import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

import './Member.scss';

const Member: React.FC<MemberProps> = ({ fullName }: MemberProps) => {
  const { t, i18n } = useTranslation('members');

  const name =
    (t(fullName + '.title') !== '' ? t(fullName + '.title') + ' ' : '') +
    (i18n.language === 'hu'
      ? t(fullName + '.lastName') + ' ' + t(fullName + '.firstName')
      : t(fullName + '.firstName') + ' ' + t(fullName + '.lastName'));

  return (
    <div className="member">
      <div className="relative mx-auto w-[250px] h-[250px] xl:w-[264px] xl:h-[264px]">
        <div className="member-picture">
          <div className="w-full h-full bg-white border-4 border-green-400 rounded-full">
            <img
              src={require(
                'src/client/assets/images/' +
                  t(fullName + '.filename') +
                  '-avatar.svg',
              )}
              alt={name}
              loading="lazy"
            />
            <LazyLoadImage
              src={require(
                'src/client/assets/images/' +
                  t(fullName + '.filename') +
                  '.webp',
              )}
              alt={name}
            />
          </div>
        </div>
      </div>
      <div className="member-info">
        <h4>
          {i18n.language === 'hu' ? (
            <Fragment>
              <span className="text-white">{t(fullName + '.title')}</span>{' '}
              {t(fullName + '.lastName')}{' '}
              <span className="text-white">{t(fullName + '.firstName')}</span>
            </Fragment>
          ) : (
            <Fragment>
              <span className="text-white">
                {t(fullName + '.title')} {t(fullName + '.firstName')}{' '}
              </span>
              {t(fullName + '.lastName')}
            </Fragment>
          )}
        </h4>
        <div className="text-white text-base">{t(fullName + '.position')}</div>
        <small className="text-white">{t(fullName + '.company')}</small>
      </div>
    </div>
  );
};

export default Member;
