import { i18nextState } from 'src/client/store/i18next/types';

const i18nextConfig: i18nextState = {
  language: null,
  fallbackLng: 'en',
  ns: [
    'navigation',
    'about',
    'mindset-shaping-training',
    'contact',
    'cookie-consent',
    'diginclude',
    'digital-knowledge-dissemination',
    'footer',
    'forbidden',
    'global',
    'impact-pledge',
    'intro',
    'members',
    'our-work-method',
    'page-not-found',
    'sign-language',
    'software-development',
    'these-are-us',
    'they-said-about-us',
    'services',
    'webshop',
    'design',
    'what-are-we-committing-to',
    'blog',
    'post',
    'privacy-policy',
  ],
};

export default i18nextConfig;
