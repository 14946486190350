import React from 'react';
import { Fancybox } from '@fancyapps/ui';

import { ReferenceProps } from 'src/client/interfaces/reference.interface';

import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

import './Reference.scss';

Fancybox.bind('[data-fancybox="reference"]');

const Reference: React.FC<ReferenceProps> = ({
  src,
  title,
  logo,
}: ReferenceProps) => {
  return (
    <div
      id="reference"
      className="relative w-full h-full rounded-3xl overflow-hidden z-0 custom-shadow-hard"
    >
      <a
        data-fancybox="reference"
        href={src ? require('src/client/assets/images/' + src) : ''}
        className="relative w-full h-full"
      >
        <LazyLoadImage
          src={src ? require('src/client/assets/images/' + src) : ''}
          alt={title}
          className="w-full h-full object-cover duration-300"
        />
        <div className="overlay-background">
          <LazyLoadImage
            src={logo ? require('src/client/assets/images/' + logo) : ''}
            alt={title}
            className="w-auto max-w-[120px] md:max-w-[150px] lg:max-w-[200px] h-auto max-h-[100px]"
          />
        </div>
      </a>
    </div>
  );
};

export default Reference;
