import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReferenceProps } from 'src/client/interfaces/reference.interface';

import { ImageProps } from 'src/client/types/image';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import { history } from 'src/client/store/configureStore';

import LandingIntro from 'src/client/components/LandingIntro/LandingIntro';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import References from 'src/client/components/References/References';
import Stacks from 'src/client/components/Stacks/Stacks';
import OurWorkMethod from 'src/client/components/OurWorkMethod/OurWorkMethod';
import Contact from 'src/client/components/Contact/Contact';

const references: ReferenceProps[] = [
  {
    src: 'minplan.webp',
    title: 'MinPlan',
    logo: 'minplan-logo.webp',
  },
  {
    src: 'presshouse47.webp',
    title: 'Press House 47',
    logo: 'presshouse47-logo.webp',
  },
  {
    src: 'redonymento.webp',
    title: 'Redőnymentő',
    logo: 'redonymento-logo.webp',
  },
  {
    src: 'coface.webp',
    title: 'Coface',
    logo: 'coface-logo.webp',
  },
  {
    src: 'gymtrackworld.webp',
    title: 'GymTrack World',
    logo: 'gymtrackworld-logo.webp',
  },
  {
    src: 'allmyles.webp',
    title: 'Allmyles',
    logo: 'allmyles-logo.webp',
  },
  {
    src: 'spectry.webp',
    title: 'Spectry',
    logo: 'spectry-logo.webp',
  },
  {
    src: 'homoki.webp',
    title: 'Homoki Ügyvédi Iroda',
    logo: 'homoki-logo.webp',
  },
  {
    src: 'promogroup.webp',
    title: 'Promogroup',
    logo: 'promogroup-logo.webp',
  },
  {
    src: 'loci-es-bibe-jelel.webp',
    title: 'Lóci és Bibe jelel',
    logo: 'loci-es-bibe-jelel-logo.webp',
  },
  {
    src: 'vavunion.webp',
    title: 'VÁV Union',
    logo: 'vavunion-logo.webp',
  },
  {
    src: 'mamma-mia-cheese.webp',
    title: 'Mamma Mia Cheese',
    logo: 'mamma-mia-cheese-logo.webp',
  },
];

const stacks: ImageProps[] = [
  {
    src: 'html5.svg',
    title: 'HTML5',
  },
  {
    src: 'css3.svg',
    title: 'CSS3',
  },
  {
    src: 'sass.svg',
    title: 'Sass',
  },
  {
    src: 'bootstrap.svg',
    title: 'Bootstrap',
  },
  {
    src: 'tailwind-css.svg',
    title: 'TailwindCSS',
  },
  {
    src: 'javascript.svg',
    title: 'JavaScript',
  },
  {
    src: 'jquery.svg',
    title: 'jQuery',
  },
  {
    src: 'jekyll.svg',
    title: 'Jekyll',
  },
  {
    src: 'typescript.svg',
    title: 'TypeScript',
  },
  {
    src: 'nodejs.svg',
    title: 'Node.JS',
  },
  {
    src: 'webpack.svg',
    title: 'Webpack',
  },
  {
    src: 'gulp.svg',
    title: 'Gulp.JS',
  },
  {
    src: 'angular.svg',
    title: 'Angular',
  },
  {
    src: 'react.svg',
    title: 'React',
  },
  {
    src: 'vue.svg',
    title: 'Vue',
  },
  {
    src: 'php.svg',
    title: 'PHP',
  },
  {
    src: 'laravel.svg',
    title: 'Laravel',
  },
  {
    src: 'wordpress.svg',
    title: 'WordPress',
  },
  {
    src: 'flutter.svg',
    title: 'Flutter',
  },
  {
    src: 'mysql.svg',
    title: 'MySQL',
  },
  {
    src: 'mongodb.svg',
    title: 'MongoDB',
  },
  {
    src: 'postgresql.svg',
    title: 'PostgreSQL',
  },
  {
    src: 'git.svg',
    title: 'Git',
  },
  {
    src: 'docker.svg',
    title: 'Docker',
  },
];

const SoftwareDevelopment = () => {
  const { t } = useTranslation(['global', 'software-development']);

  const { hash } = history.location;

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1) });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="software-development">
      <PageTitle title={t('pageTitle', { ns: 'software-development' })} />
      <LandingIntro
        title={t('title', { ns: 'software-development' })}
        paragraph={t('checkOurReferencesAndLetsWorkTogether', {
          ns: 'global',
        })}
        backgroundImageSrc="software-development-cover.webp"
        illustrationImageSrc="software-development-illustration.webp"
      />
      <div className="bg-both-side">
        <References references={references} />
        <Stacks title={t('stack', { ns: 'global' })} stacks={stacks} />
        <OurWorkMethod />
      </div>
      <Contact />
    </div>
  );
};

export default SoftwareDevelopment;
