import { ContactAction, ContactState } from './types';
import {
  SET_CONTACT,
  SET_CONTACT_ERRORS,
  SET_CONTACT_TOUCHED,
  IS_SENT_SUCCESSFULLY,
  IS_SENT_UNSUCCESSFULLY,
  RESET_CONTACT,
} from './constants';

const initialState: ContactState = {
  data: {
    name: '',
    email: '',
    subject: '',
    message: '',
  },
  errors: {
    name: '',
    email: '',
    subject: '',
    message: '',
  },
  touched: {
    name: false,
    email: false,
    subject: false,
    message: false,
  },
  isSentSuccessfully: false,
  isSentUnsuccessfully: false,
};

export default (
  state: ContactState = initialState,
  action: ContactAction,
): ContactState => {
  switch (action.type) {
    case SET_CONTACT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case SET_CONTACT_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case SET_CONTACT_TOUCHED:
      return {
        ...state,
        touched: {
          ...state.touched,
          ...action.payload,
        },
      };
    case IS_SENT_SUCCESSFULLY:
      return {
        ...state,
        isSentSuccessfully: true,
      };
    case IS_SENT_UNSUCCESSFULLY:
      return {
        ...state,
        isSentUnsuccessfully: true,
      };
    case RESET_CONTACT:
      return initialState;
    default:
      return state;
  }
};
