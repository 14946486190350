import { RESET_POSTS, SET_POSTS, SET_POSTS_ERROR } from './constants';

import { PostsAction, PostsState } from './types';

const initialState: PostsState = {
  data: [],
  totalCount: 0,
  error: '',
};

export default (
  state: PostsState = initialState,
  action: PostsAction,
): PostsState => {
  switch (action.type) {
    case SET_POSTS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_POSTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_POSTS:
      return initialState;
    default:
      return state;
  }
};
