import { i18nextAction, i18nextState } from './types';
import { SET_LANGUAGE } from './constants';

const initialState: i18nextState = {
  language: null,
  fallbackLng: null,
  whitelist: [],
  ns: [],
  defaultNS: null,
  debug: false,
};

export default (
  state: i18nextState = initialState,
  action: i18nextAction,
): i18nextState => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
