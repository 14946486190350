import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

import { isServer } from './globals';

const createNoopStorage = () => {
  const getItem = (_key: string) => {
    return Promise.resolve(null);
  };

  const setItem = (_key: string, value: string) => {
    return Promise.resolve(value);
  };

  const removeItem = (_key: string) => {
    return Promise.resolve();
  };

  return { getItem, setItem, removeItem };
};

const storage = isServer ? createNoopStorage() : createWebStorage('local');

export default storage;
