import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageProps } from 'src/client/types/image';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import { history } from 'src/client/store/configureStore';

import LandingIntro from 'src/client/components/LandingIntro/LandingIntro';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import Contact from 'src/client/components/Contact/Contact';
import Gallery from 'src/client/components/Gallery/Gallery';
import Partners from 'src/client/components/Partners/Partners';
import TheySaidAboutUs from 'src/client/components/TheySaidAboutUs/TheySaidAboutUs';

const partners: ImageProps[] = [
  {
    src: 'prezi.webp',
    title: 'Prezi',
  },
  {
    src: 'skilly.webp',
    title: 'Skilly',
  },
  {
    src: 'szikra.webp',
    title: 'Szikra Coworking',
  },
];

const MindsetShapingTraining = () => {
  const { t } = useTranslation(['global', 'mindset-shaping-training']);

  const { hash } = history.location;

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1) });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="mindset-shaping-training">
      <PageTitle title={t('pageTitle', { ns: 'mindset-shaping-training' })} />
      <LandingIntro
        title={t('title', { ns: 'mindset-shaping-training' })}
        paragraph={t('checkOurReferencesAndLetsWorkTogether', { ns: 'global' })}
        backgroundImageSrc="mindset-shaping-training-cover.webp"
        illustrationImageSrc="mindset-shaping-training-illustration.webp"
      />
      <div className="bg-right-side bg-right-top">
        <Partners
          title={t('ourReferences', { ns: 'global' })}
          partners={partners}
        />
        <TheySaidAboutUs />
        <Gallery />
      </div>
      <Contact />
    </div>
  );
};

export default MindsetShapingTraining;
