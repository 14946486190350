import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';

import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

import './Services.scss';

interface ServiceProps {
  title: string;
  url: string;
}

const Services: React.FC = () => {
  const { t } = useTranslation('services');

  const Service: React.FC<ServiceProps> = ({ title, url }: ServiceProps) => {
    return (
      <div className="relative p-6 w-full sm:w-1/2 lg:w-1/3 text-center">
        <FadeInAnimation direction="up">
          <Link to={url}>
            <LazyLoadImage
              src={require('src/client/assets/images/green-circle.webp')}
              alt={title}
              className="relative mx-auto w-full max-w-[250px] md:max-w-[350px] h-auto z-10 duration-300 hover:rotate-45 hover:scale-105"
            />
            <div className="centered">
              <div className="text-lg md:text-xl lg:text-lg xl:text-xl text-white leading-6 md:leading-[36px] uppercase whitespace-pre-line">
                {title}
              </div>
              {!isDesktop ? (
                <img
                  src={require('src/client/assets/images/tap.svg')}
                  alt="Tap"
                  className="tap"
                />
              ) : null}
            </div>
          </Link>
        </FadeInAnimation>
      </div>
    );
  };

  return (
    <section id="services">
      <div className="container">
        <h2>{t('title')}</h2>
      </div>
      <div className="lg:py-20 world-map">
        <div className="container">
          <div className="flex flex-row flex-wrap justify-center">
            <Service
              title={t('softwareDevelopment')}
              url="/software-development"
            />
            <Service title={t('webshop')} url="/webshop" />
            <Service title={t('design')} url="/design" />
            <Service
              title={t('mindsetShapingTraining')}
              url="/mindset-shaping-training"
            />
            <Service
              title={t('digitalKnowledgeDissemination')}
              url="/digital-knowledge-dissemination"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
