import { GeolocationProps } from 'src/client/interfaces/geolocation.interface';

import { GeolocationAction } from './types';
import { SET_GEOLOCATION, SET_GEOLOCATION_ERROR } from './constants';

export const setGeolocation = (
  payload: GeolocationProps,
): GeolocationAction => {
  return {
    type: SET_GEOLOCATION,
    payload,
  };
};

export const setGeolocationError = (payload: string): GeolocationAction => {
  return {
    type: SET_GEOLOCATION_ERROR,
    payload,
  };
};
