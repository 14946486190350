import {
  ContactErrorProps,
  ContactProps,
  ContactTouchedProps,
} from 'src/client/interfaces/contact.interface';
import { ContactAction } from './types';
import {
  SET_CONTACT,
  SET_CONTACT_ERRORS,
  SET_CONTACT_TOUCHED,
  IS_SENT_SUCCESSFULLY,
  IS_SENT_UNSUCCESSFULLY,
  RESET_CONTACT,
} from './constants';

export const setContact = (
  payload: ContactProps | { [x: string]: string },
): ContactAction => {
  return {
    type: SET_CONTACT,
    payload,
  };
};

export const setContactErrors = (payload: ContactErrorProps): ContactAction => {
  return {
    type: SET_CONTACT_ERRORS,
    payload,
  };
};

export const setContactTouched = (
  payload: ContactTouchedProps | { [x: string]: boolean },
): ContactAction => {
  return {
    type: SET_CONTACT_TOUCHED,
    payload,
  };
};

export const setIsSentSuccessfully = (): ContactAction => {
  return {
    type: IS_SENT_SUCCESSFULLY,
  };
};

export const setIsSentUnsuccessfully = (): ContactAction => {
  return {
    type: IS_SENT_UNSUCCESSFULLY,
  };
};

export const resetContact = (): ContactAction => {
  return {
    type: RESET_CONTACT,
  };
};
