import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { scrollToTop } from 'src/client/helpers/globals';

import PageTitle from 'src/client/components/PageTitle/PageTitle';

import './PageNotFound.scss';

const PageNotFound: React.FC = () => {
  const { t } = useTranslation('page-not-found');

  useEffect(() => {
    scrollToTop({});
  }, []);

  return (
    <section id="page-not-found" className="pb-20 lg:py-40">
      <PageTitle title="404" />
      <div className="container">
        <div className="flex flex-col lg:flex-row items-center overflow-hidden">
          <div className="flex-1 w-full">
            <img
              src={require('src/client/assets/images/galaxy.svg')}
              alt={t('galaxy')}
              className="mx-auto animate-spin"
              loading="lazy"
            />
          </div>
          <div className="flex-1 w-full">
            <h1 className="mb-4 md:text-[100px]">404</h1>
            <div className="mb-4 text-white text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              {t('subtitle')}
            </div>
            <p className="mb-4">{t('text')}</p>
            <NavLink to="/" className="inline-block mt-4 btn btn-green">
              {t('buttonText')}
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
