import React from 'react';
import { UilPlayCircle } from '@iconscout/react-unicons';

interface PlayIconProps {
  className?: string;
}

const PlayIcon = ({ className = '' }: PlayIconProps) => (
  <div
    className={`flex flex-row justify-center items-center w-[60px] h-[60px] bg-green rounded-full cursor-pointer duration-300 hover:scale-105 ${className}`}
  >
    <UilPlayCircle className="w-[60px] h-[60px] text-white" />
  </div>
);

export default PlayIcon;
