import React from 'react';
import { useTranslation } from 'react-i18next';

import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';

const images: string[] = [
  'logo-and-brand-design.svg',
  'design.svg',
  'mobile-app-design.svg',
  'email-signature.svg',
  'maintenance.svg',
];

const OurWorkMethod: React.FC = () => {
  const { t } = useTranslation('our-work-method');

  return (
    <section id="our-work-method" className="pt-20">
      <div className="container">
        <h2>{t('title')}</h2>
        <div className="card rounded-3xl custom-shadow-hard">
          <h4>{t('keyOfSuccess.title')}</h4>
          <p className="text-white">{t('keyOfSuccess.text')}</p>
        </div>
        {t('progresses', { returnObjects: true }).map(
          (progress: any, index: number) => (
            <FadeInAnimation key={index} direction="down">
              <div className="flex flex-col md:flex-row justify-between card rounded-3xl custom-shadow-hard">
                <img
                  src={
                    images[index]
                      ? require('src/client/assets/images/' + images[index])
                      : ''
                  }
                  alt={t(progress.title)}
                  className="mx-auto mb-8 md:m-0 md:pl-8 md:order-2 w-full max-w-[200px]"
                />
                <div className="md:order-1">
                  <h4 className="uppercase">{progress.title}</h4>
                  <p className="text-white">{progress.text}</p>
                </div>
              </div>
            </FadeInAnimation>
          ),
        )}
      </div>
    </section>
  );
};

export default OurWorkMethod;
