import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRouterState } from '@lagunovsky/redux-react-router';
import { useTranslation } from 'react-i18next';

import { RootState } from 'src/client';

import { scrollToElement } from 'src/client/helpers/globals';

import LanguageSwitcher from 'src/client/components/LanguageSwitcher/LanguageSwitcher';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

import './Header.scss';

const logoHidingByRoutes: string[] = [
  '/software-development',
  '/webshop',
  '/design',
  '/mindset-shaping-training',
  '/digital-knowledge-dissemination',
  '/digital-knowledge-dissemination/diginclude',
];

const Header: React.FC = () => {
  const { t } = useTranslation(['global', 'navigation']);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const router: ReduxRouterState = useSelector(
    (state: RootState) => state.router,
  );

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <header
      className={`${
        logoHidingByRoutes.includes(router.location.pathname)
          ? 'hidden'
          : 'block'
      } fixed top-0 left-0 right-0 z-[1000] bg-mine-shaft`}
    >
      <div className="container">
        <div className="flex flex-row justify-between items-center menu-bar py-2">
          <NavLink to="/" onClick={handleMenuClose}>
            <LazyLoadImage
              src={require('src/client/assets/logos/sc-logo.svg')}
              alt={t('logo', { ns: 'global' })}
              className="h-16 logo"
            />
          </NavLink>
          <div className="flex flex-row justify-between items-center">
            <div
              className={`fixed lg:relative bg-mine-shaft menu ${
                isMenuOpen ? 'show' : ''
              }`}
            >
              <nav className="flex flex-col lg:flex-row items-center absolute lg:relative w-full whitespace-nowrap lg:whitespace-normal">
                <Link
                  to="/#services"
                  className="mx-2 px-2 py-3 lg:py-2 text-lg leading-7 lg:text-sm"
                  onClick={() => {
                    handleMenuClose();
                    scrollToElement({ elementId: 'services', top: 120 });
                  }}
                >
                  {t('services', { ns: 'navigation' })}
                </Link>
                <NavLink
                  to="/impact-pledge"
                  className="mx-2 px-2 py-3 lg:py-2 text-lg leading-7 lg:text-sm"
                  onClick={handleMenuClose}
                >
                  {t('impactPledge', { ns: 'navigation' })}
                </NavLink>
                <NavLink
                  to="/sign-language"
                  className="mx-2 px-2 py-3 lg:py-2 text-lg leading-7 lg:text-sm"
                  onClick={handleMenuClose}
                >
                  {t('signLanguage', { ns: 'navigation' })}
                </NavLink>
                <NavLink
                  to="/about"
                  className="mx-2 px-2 py-3 lg:py-2 text-lg leading-7 lg:text-sm"
                  onClick={handleMenuClose}
                >
                  {t('about', { ns: 'navigation' })}
                </NavLink>
                <NavLink
                  to="/blog"
                  className="mx-2 px-2 py-3 lg:py-2 text-lg leading-7 lg:text-sm"
                  onClick={handleMenuClose}
                >
                  {t('blog', { ns: 'navigation' })}
                </NavLink>
                <Link
                  to="#contact"
                  className="lg:mx-4 my-2 lg:my-0 text-lg lg:text-sm btn btn-green"
                  onClick={() => {
                    handleMenuClose();
                    scrollToElement({ elementId: 'contact', top: 50 });
                  }}
                >
                  {t('contact', { ns: 'navigation' })}
                </Link>
              </nav>
            </div>
            <LanguageSwitcher />
            <button
              type="button"
              className="lg:hidden p-2 focus:outline-none"
              aria-controls="menu-toggler"
              aria-expanded="false"
              aria-label="Mobile toggle menu"
              onClick={handleMenuToggle}
            >
              <div
                className={`relative w-8 h-6 rotate-0 cursor-pointer menu-icon ${
                  isMenuOpen ? 'open' : ''
                }`}
              >
                <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
