import { ReduxDetectorOptions, ReduxDetector } from './types';

const reduxDetector: ReduxDetector = {
  name: 'redux',
  lookup(options: ReduxDetectorOptions) {
    let found: string | undefined;

    if (options.lookupRedux) {
      const language = options.lookupRedux().language;

      if (language) found = language;
    }

    return found;
  },
  cacheUserLanguage(newLanguage: string, options: ReduxDetectorOptions) {
    if (options.cacheUserLanguageRedux && options.lookupRedux) {
      const currentLanguage: string = options.lookupRedux().language;

      if (currentLanguage !== newLanguage) {
        options.cacheUserLanguageRedux(newLanguage);
      }
    }
  },
};

export default reduxDetector;
