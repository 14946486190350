import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import { history } from 'src/client/store/configureStore';

import Contact from 'src/client/components/Contact/Contact';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';
import LanguageSwitcher from 'src/client/components/LanguageSwitcher/LanguageSwitcher';

import './DigInclude.scss';

const DigInclude: React.FC = () => {
  const { t } = useTranslation(['global', 'navigation', 'diginclude']);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { hash } = history.location;

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1) });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="diginclude">
      <PageTitle title={t('title', { ns: 'diginclude' })} />
      <section id="intro">
        <div className="relative h-[600px] md:h-[850px] overflow-hidden">
          <LazyLoadImage
            src={require('src/client/assets/images/digital-knowledge-dissemination-cover.webp')}
            alt={t('backgroundImage', { ns: 'global' })}
            className="absolute top-0 left-0 right-0 w-full h-full object-cover object-[40%_center]"
          />
          <div className="container relative h-full">
            <LazyLoadImage
              src={require('src/client/assets/images/digital-knowledge-dissemination-illustration.webp')}
              alt={t('illustration', { ns: 'global' })}
              className="hidden xl:block absolute right-0 bottom-0 xl:max-h-[700px]"
            />
            <div className="relative pt-8 pb-16">
              <div
                className={`fixed lg:relative menu ${isMenuOpen ? 'show' : ''}`}
              >
                <button
                  type="button"
                  className="lg:hidden absolute top-8 right-8 p-2 focus:outline-none"
                  aria-controls="menu-toggler"
                  aria-expanded="false"
                  aria-label="Mobile toggle menu"
                  onClick={handleMenuToggle}
                >
                  <div className="relative w-8 h-6 rotate-0 cursor-pointer menu-icon open">
                    <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                    <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                    <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                    <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                  </div>
                </button>
                <nav className="flex flex-col lg:flex-row justify-center items-center mb-8 px-8 py-2 w-full lg:max-w-max h-full bg-white lg:bg-white/70 text-center lg:rounded-[50px] whitespace-nowrap lg:whitespace-normal lg:custom-shadow-soft">
                  <Link
                    to="/#services"
                    className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                    onClick={() => {
                      handleMenuClose();
                      scrollToElement({ elementId: 'services', top: 120 });
                    }}
                  >
                    {t('services', { ns: 'navigation' })}
                  </Link>
                  <NavLink
                    to="/impact-pledge"
                    className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                    onClick={handleMenuClose}
                  >
                    {t('impactPledge', { ns: 'navigation' })}
                  </NavLink>
                  <NavLink
                    to="/sign-language"
                    className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                    onClick={handleMenuClose}
                  >
                    {t('signLanguage', { ns: 'navigation' })}
                  </NavLink>
                  <NavLink
                    to="/about"
                    className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                    onClick={handleMenuClose}
                  >
                    {t('about', { ns: 'navigation' })}
                  </NavLink>
                  <NavLink
                    to="/blog"
                    className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                    onClick={handleMenuClose}
                  >
                    {t('blog', { ns: 'navigation' })}
                  </NavLink>
                  <Link
                    to="#contact"
                    className="lg:mx-4 my-2 lg:my-0 text-lg lg:text-sm btn btn-green"
                    onClick={() => {
                      handleMenuClose();
                      scrollToElement({ elementId: 'contact', top: 0 });
                    }}
                  >
                    {t('contact', { ns: 'navigation' })}
                  </Link>
                  <div className="hidden lg:block">
                    <LanguageSwitcher />
                  </div>
                </nav>
              </div>
              <div className="flex flex-row justify-between items-center mb-12">
                <NavLink to="/">
                  <LazyLoadImage
                    src={require('src/client/assets/logos/sc-logo-white.svg')}
                    alt={t('logo', { ns: 'global' })}
                    className="h-16 logo"
                  />
                </NavLink>
                <div className="flex flex-row items-center">
                  <div className="lg:hidden">
                    <LanguageSwitcher />
                  </div>
                  <button
                    type="button"
                    className="lg:hidden p-2 focus:outline-none"
                    aria-controls="menu-toggler"
                    aria-expanded="false"
                    aria-label="Mobile toggle menu"
                    onClick={handleMenuToggle}
                  >
                    <div className="relative w-8 h-6 rotate-0 cursor-pointer menu-icon">
                      <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                      <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                      <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                      <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                    </div>
                  </button>
                </div>
              </div>
              <LazyLoadImage
                src={require('src/client/assets/images/co-funded-by-the-european-union.webp')}
                alt="Co-funded by the European Union logo"
                className="mb-4 max-w-[250px] md:max-w-[400px]"
              />
              <h1 className="mb-4 xl:max-w-[45%] text-xl leading-9 md:text-3xl md:leading-[54px] whitespace-pre-line">
                Developing Digital Skills in Disadvantaged Groups
              </h1>
              <div className="mb-4 text-white text-lg leading-7 md:text-xl md:leading-9 font-medium">
                01/03/2022 - 29/02/2024
              </div>
              <LazyLoadImage
                src={require('src/client/assets/images/digInclude.webp')}
                alt="DigInclude logo"
                className="mb-4 max-w-[200px] md:max-w-[400px]"
              />
              <div className="mb-4 xl:max-w-[45%] text-white text-lg leading-7 md:text-xl md:leading-9 font-medium">
                An Erasmus+ project co-funded by the European Union. Thank you!
              </div>
              <div className="text-white text-sm">
                <span className="font-bold">Project Reference</span>:
                2021-2-HU01-KA210-ADU-000049819
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-right-side bg-right-top">
        <section id="about" className="pt-20">
          <div className="container">
            <h2>About</h2>
            <div className="card rounded-3xl custom-shadow-hard">
              <h3 className="text-green-400">Why?</h3>
              <p>
                The shortage of skilled workers presents an opportunity for
                disadvantaged individuals to enter the workforce. However, many
                face a skills gap that hinders their ability to secure
                employment. Enhancing digital literacy significantly improves
                their prospects in the job market.
                <br />
                Employment opportunities for disadvantaged groups are essential
                for fostering equal opportunities. However, access to the job
                market must be feasible in practice. A common challenge among
                various disadvantaged groups is their underrepresentation or
                exclusion from digital professions. Although the reasons for
                this barrier are multifaceted, adult education offers a powerful
                means of addressing this issue.
              </p>
            </div>
            <div className="card rounded-3xl custom-shadow-hard">
              <h3 className="text-green-400">What?</h3>
              <p>
                Through this project, we aim to tackle two interconnected
                challenges currently faced by the European Union and its member
                states: the need for access to lifelong learning, upskilling,
                and digital literacy among many people in Europe, particularly
                disadvantaged individuals; and the widespread shortage of
                skilled workers in the digital economy and beyond. Within this
                project and beyond, our commitments include:
                <br />
                Establishing a dedicated community of adult learners in Hungary
                and the Netherlands, fostering ongoing dialogue, long-term
                community learning, and the development of collaborative,
                progressive community initiatives.
                <br />
                Supporting young disadvantaged individuals in enhancing their
                digital skills, literacy, and well-being to enhance their
                employability in a digital environment.
              </p>
            </div>
            <div className="card rounded-3xl custom-shadow-hard">
              <h3 className="text-green-400">Who?</h3>
              <p>
                The DigInclude project is led by SignCoders, a Hungarian social
                enterprise that employs young deaf professionals. The Hekate
                Conscious Ageing Foundation in the Netherlands proudly partners
                in this project.
              </p>
              <div className="flex flex-col md:flex-row mt-8 gap-8 md:gap-16">
                <LazyLoadImage
                  src={require('src/client/assets/images/hekate-foundation.webp')}
                  alt="Hekate Foundation logo"
                  className="h-16 object-contain"
                />
                <LazyLoadImage
                  src={require('src/client/assets/logos/sc-logo-white.svg')}
                  alt="SignCoders logo"
                  className="h-16 object-contain"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-8 mb-12">
              <LazyLoadImage
                src={require('src/client/assets/images/digInclude-screenshot-001.webp')}
                alt="Screenshot 001"
                className="w-full md:w-3/5 object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/digInclude-screenshot-002.webp')}
                alt="Screenshot 002"
                className="w-full md:w-2/5 object-contain"
              />
            </div>
            <div className="mb-0 card rounded-3xl custom-shadow-hard">
              <h3 className="text-green-400">Driven by Beliefs</h3>
              <p>
                We firmly believe that post-COVID economic and social recovery
                should be guided by the intentions, aspirations, and practical
                needs of local communities and their members.We also advocate
                for a post-COVID recovery that prioritizes access to digital
                skills and fosters shared community responsibilities.
                <br />
                Furthermore, we emphasize the importance of empowering local
                communities to drive and take ownership of these transitions,
                necessitating a diverse range of adult learning activities and
                modules.
              </p>
            </div>
          </div>
        </section>
        <section className="pt-20">
          <div className="py-12 bg-green-linear-gradient">
            <div className="container">
              <p>
                &quot;While using technology gives many of us brilliant
                advantages and benefits, those who have no access to internet or
                cannot access online spaces barrier-free automatically get into
                a position of disadvantage. Imagine people with no access to
                internet. Imagine people of disadvantaged groups with lack of
                digital literacy. Imagine great minds with physical
                disabilities: blind, deaf, handicapped and so on. The truth is
                that we don&apos;t have information available and accessible
                equally for everyone. We still don&apos;t have spaces that
                promote barrier-free accessibility. We still don&apos;t design
                safe digital spaces to support the human needs of disadvantaged
                groups.&quot;
              </p>
            </div>
          </div>
        </section>
        <section id="knowledge-base" className="pt-20">
          <div className="container">
            <h2>Knowledge base</h2>
            <div className="m-[0_auto_4rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              Courses, educational articles, knowledge pieces created by Hekate
              and SignCoders during the project
            </div>
            <div className="flex flex-col md:flex-row justify-between card rounded-3xl custom-shadow-hard">
              <LazyLoadImage
                src={require('src/client/assets/images/quick-to-learn-digital-professions.webp')}
                alt="Quick to Learn Digital Professions main image"
                className="mx-auto mb-8 md:m-0 md:pl-8 md:order-2 w-full md:w-[250px] lg:w-[450px] object-contain"
              />
              <div className="md:order-1">
                <h3 className="text-green-400">
                  Quick to Learn Digital Professions
                </h3>
                <p>
                  Quick to Learn Digital Professions In today&apos;s digital
                  age, many aspects of our lives, including communication,
                  education, banking, and work, are conducted online. However,
                  not everyone has access to or fully participates in digital
                  activities. SignCoders provides insights into job
                  opportunities based on computer technologies and offers
                  guidance on starting a digital career.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
              <LazyLoadImage
                src={require('src/client/assets/images/quick-to-learn-digital-professions-preview-001.webp')}
                alt="Quick to Learn Digital Professions preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/quick-to-learn-digital-professions-preview-002.webp')}
                alt="Quick to Learn Digital Professions preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/quick-to-learn-digital-professions-preview-003.webp')}
                alt="Quick to Learn Digital Professions preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="flex flex-col md:flex-row justify-between card rounded-3xl custom-shadow-hard">
              <LazyLoadImage
                src={require('src/client/assets/images/identity-equity-inclusion.webp')}
                alt="Identity, equity, inclusion main image"
                className="mx-auto mb-8 md:m-0 md:pl-8 md:order-2 w-full md:w-[250px] lg:w-[450px] object-contain"
              />
              <div className="md:order-1">
                <h3 className="text-green-400">Identity, equity, inclusion</h3>
                <p>
                  Identity, equity, inclusion The interactive course developed
                  by SignCoders as part of the DigInclude project aims to help
                  individuals of all backgrounds quickly understand the basics
                  of identity, equity, and inclusion on a personal level through
                  self-reflection.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
              <LazyLoadImage
                src={require('src/client/assets/images/identity-equity-inclusion-preview-001.webp')}
                alt="Identity, equity, inclusion preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/identity-equity-inclusion-preview-002.webp')}
                alt="Identity, equity, inclusion preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/identity-equity-inclusion-preview-003.webp')}
                alt="Identity, equity, inclusion preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="flex flex-col md:flex-row justify-between card rounded-3xl custom-shadow-hard">
              <LazyLoadImage
                src={require('src/client/assets/images/consciously-digital-online-course.webp')}
                alt="Consciously Digital Online Course"
                className="mx-auto mb-8 md:m-0 md:pl-8 md:order-2 w-auto max-h-[300px] object-contain"
              />
              <div className="md:order-1">
                <h3 className="text-green-400">
                  Consciously Digital Online Course
                </h3>
                <p>
                  Consciously Digital Online Course We&apos;ve developed an
                  online course about Digital Readiness based on our
                  self-developed model. The course is downloadable from our
                  website in 3 languages (English, Dutch and Hungarian) and
                  covers 14 topics as the core concept of Digital Readiness.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
              <LazyLoadImage
                src={require('src/client/assets/images/consciously-digital-online-course-preview-001.webp')}
                alt="Consciously Digital Online Course preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/consciously-digital-online-course-preview-002.webp')}
                alt="Consciously Digital Online Course preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/consciously-digital-online-course-preview-003.webp')}
                alt="Consciously Digital Online Course preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="flex flex-col md:flex-row justify-between card rounded-3xl custom-shadow-hard">
              <LazyLoadImage
                src={require('src/client/assets/images/technology-blessing-or-curse.webp')}
                alt="Technology: blessing or curse? 1 question, 5 opinions! main image"
                className="mx-auto mb-8 md:m-0 md:pl-8 md:order-2 w-auto max-h-[300px] object-contain"
              />
              <div className="md:order-1">
                <h3 className="text-green-400">
                  Technology: blessing or curse? 1 question, 5 opinions!
                </h3>
                <p>
                  Technology: blessing or curse? 1 question, 5 opinions! We have
                  also initiated exciting club discussions, which have now
                  become quite a tradition. Our first discussion was an
                  introduction to the digital society, Artificial Intelligence
                  and the changing world. In this article we want to present the
                  general view of the discussion and some basic introduction to
                  the topic.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between card rounded-3xl custom-shadow-hard">
              <LazyLoadImage
                src={require('src/client/assets/images/understanding-digital-wellbeing.webp')}
                alt="Understanding Digital Wellbeing main image"
                className="mx-auto mb-8 md:m-0 md:pl-8 md:order-2 w-auto max-h-[300px] object-contain"
              />
              <div className="md:order-1">
                <h3 className="text-green-400">
                  Understanding Digital Wellbeing
                </h3>
                <p>
                  &quot;Digital Wellbeing is the optimal state of health,
                  personal fulfilment, interpersonal satisfaction and
                  body-mind-spirit integrity that one can achieve while using
                  technology.&quot; (...) Our relationship with technology
                  defines the quality of certain areas of our lives. The Digital
                  Wellness Institute designed a map of the areas where one could
                  (/should?) focus on for great Digital Wellbeing.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between card rounded-3xl custom-shadow-hard">
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-and-digital-inclusion.webp')}
                alt="Digital Wellbeing and Digital Inclusion: What's the connection? main image"
                className="mx-auto mb-8 md:m-0 md:pl-8 md:order-2 w-auto max-h-[300px] object-contain"
              />
              <div className="md:order-1">
                <h3 className="text-green-400">
                  Digital Wellbeing and Digital Inclusion: what&apos;s the
                  connection?
                </h3>
                <p>
                  During the project we had a{' '}
                  <u>
                    professional forum with two workshops in Budapest, Hungary
                  </u>
                  . This was for the lovely community of one of our strategic
                  partners, SignCoders. The topic therefore was given: Digital
                  Inclusion. However we really wanted to take a look at it from
                  a different point of view and approach through Digital
                  Wellbeing, a topic that we have some expertise in. We&apos;d
                  like to share with you how we see the connection between these
                  two areas!
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-and-digital-inclusion-preview-001.webp')}
                alt="Digital Wellbeing and Digital Inclusion: What's the connection? preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-and-digital-inclusion-preview-002.webp')}
                alt="Digital Wellbeing and Digital Inclusion: What's the connection? preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-and-digital-inclusion-preview-003.webp')}
                alt="Digital Wellbeing and Digital Inclusion: What's the connection? preview 003"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-and-digital-inclusion-preview-004.webp')}
                alt="Digital Wellbeing and Digital Inclusion: What's the connection? preview 004"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-and-digital-inclusion-preview-005.webp')}
                alt="Digital Wellbeing and Digital Inclusion: What's the connection? preview 005"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-and-digital-inclusion-preview-006.webp')}
                alt="Digital Wellbeing and Digital Inclusion: What's the connection? preview 006"
                className="w-full object-contain"
              />
            </div>
          </div>
        </section>
        <section className="pt-20">
          <div className="py-12 bg-green-linear-gradient">
            <div className="container">
              <p>
                &quot;Yes, we do promote barrier-free digital web design,
                user-need based coding and inclusive digital spaces and
                activities, but first and more important we need to be open to
                listen and empathise.&quot;
              </p>
            </div>
          </div>
        </section>
        <section id="events" className="pt-20">
          <div className="container">
            <h2>Events</h2>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              Software tester workshop series
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <LazyLoadImage
                src={require('src/client/assets/images/software-tester-workshop-series-preview-001.webp')}
                alt="Software tester workshop series preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/software-tester-workshop-series-preview-002.webp')}
                alt="Software tester workshop series preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/software-tester-workshop-series-preview-003.webp')}
                alt="Software tester workshop series preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              Accessibility tester workshop series
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <LazyLoadImage
                src={require('src/client/assets/images/accessibility-tester-workshop-series-preview-001.webp')}
                alt="Accessibility tester workshop series preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/accessibility-tester-workshop-series-preview-002.webp')}
                alt="Accessibility tester workshop series preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/accessibility-tester-workshop-series-preview-003.webp')}
                alt="Accessibility tester workshop series preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              Inclusion - Deaf Youth event
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <LazyLoadImage
                src={require('src/client/assets/images/inclusion-deaf-youth-event-preview-001.webp')}
                alt="Inclusion - Deaf Youth event preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/inclusion-deaf-youth-event-preview-002.webp')}
                alt="Inclusion - Deaf Youth event preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/inclusion-deaf-youth-event-preview-003.webp')}
                alt="Inclusion - Deaf Youth event preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              ELTE BGGYK dissemination
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <LazyLoadImage
                src={require('src/client/assets/images/elte-bggyk-dissemination-preview-001.webp')}
                alt="ELTE BGGYK dissemination preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/elte-bggyk-dissemination-preview-002.webp')}
                alt="ELTE BGGYK dissemination preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/elte-bggyk-dissemination-preview-003.webp')}
                alt="ELTE BGGYK dissemination preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              Digital Wellbeing - Event
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-event-preview-001.webp')}
                alt="Digital Wellbeing - Event preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-event-preview-002.webp')}
                alt="Digital Wellbeing - Event preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/digital-wellbeing-event-preview-003.webp')}
                alt="Digital Wellbeing - Event preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              CV workshop
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <LazyLoadImage
                src={require('src/client/assets/images/cv-workshop-preview-001.webp')}
                alt="CV workshop preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/cv-workshop-preview-002.webp')}
                alt="CV workshop preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/cv-workshop-preview-003.webp')}
                alt="CV workshop preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              Cinkotai School dissemination
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <LazyLoadImage
                src={require('src/client/assets/images/cinkotai-school-dissemination-preview-001.webp')}
                alt="Cinkotai School dissemination preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/cinkotai-school-dissemination-preview-002.webp')}
                alt="Cinkotai School dissemination preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/cinkotai-school-dissemination-preview-003.webp')}
                alt="Cinkotai School dissemination preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              10 IT careers - Online event
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <LazyLoadImage
                src={require('src/client/assets/images/10-it-careers-online-event-preview-001.webp')}
                alt="10 IT careers - Online event preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/10-it-careers-online-event-preview-002.webp')}
                alt="10 IT careers - Online event preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/10-it-careers-online-event-preview-003.webp')}
                alt="10 IT careers - Online event preview 003"
                className="w-full object-contain"
              />
            </div>
            <div className="m-[0_auto_2rem_auto] max-w-[1000px] text-green-400 text-center text-xl leading-9 md:text-2xl md:leading-[46px] font-medium">
              Eötvös career guidance
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <LazyLoadImage
                src={require('src/client/assets/images/eotvos-career-guidance-preview-001.webp')}
                alt="Eötvös career guidance preview 001"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/eotvos-career-guidance-preview-002.webp')}
                alt="Eötvös career guidance preview 002"
                className="w-full object-contain"
              />
              <LazyLoadImage
                src={require('src/client/assets/images/eotvos-career-guidance-preview-003.webp')}
                alt="Eötvös career guidance preview 003"
                className="w-full object-contain"
              />
            </div>
          </div>
        </section>
        <section id="downloadable-materials" className="pt-20">
          <div className="container">
            <h2>Downloadable materials</h2>
            <ul className="flex flex-col lg:flex-row gap-4 lg:gap-12">
              <li>
                <a
                  href={require('src/client/assets/pdfs/quick-to-learn-digital-professions.pdf')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex flex-row items-center"
                >
                  Quick to learn digital professions
                  <img
                    src={require('src/client/assets/logos/pdf.svg')}
                    alt="Quick to learn digital professions pdf"
                    className="ml-2 w-full max-w-[40px] lg:max-w-[40px]"
                  />
                </a>
              </li>
              <li>
                <a
                  href={require('src/client/assets/pdfs/identity-equity-inclusion.pdf')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex flex-row items-center"
                >
                  Identity equity inclusion
                  <img
                    src={require('src/client/assets/logos/pdf.svg')}
                    alt="Identity equity inclusion pdf"
                    className="ml-2 w-full max-w-[40px] lg:max-w-[40px]"
                  />
                </a>
              </li>
              <li>
                <a
                  href={require('src/client/assets/pdfs/consciously-digital-online-course.pdf')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex flex-row items-center"
                >
                  Consciously digital online course
                  <img
                    src={require('src/client/assets/logos/pdf.svg')}
                    alt="Consciously digital online course pdf"
                    className="ml-2 w-full max-w-[40px] lg:max-w-[40px]"
                  />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section id="eu-disclaimer-image" className="pt-20">
          <div className="container">
            <LazyLoadImage
              src={require('src/client/assets/images/en-ref-old-digInclude.webp')}
              alt="EU disclaimer image"
              className="w-full border-8 border-solid border-green-400 rounded-3xl object-contain"
            />
          </div>
        </section>
      </div>
      <Contact />
    </div>
  );
};

export default DigInclude;
