import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider, { Settings as SlickSettings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { TestimonialProps } from 'src/client/interfaces/testimonial.interface';

import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

import './TheySaidAboutUs.scss';

const slickSettings: SlickSettings = {
  className: 'custom-slick',
  centerMode: true,
  centerPadding: '120px',
  dots: true,
  infinite: true,
  slidesToShow: 3,
  swipeToSlide: true,
  arrows: false,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        centerPadding: '0',
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const TheySaidAboutUs: React.FC = () => {
  const { t } = useTranslation('they-said-about-us');

  return (
    <section id="they-said-about-us" className="pt-20">
      <div className="container">
        <h2>{t('title')}</h2>
      </div>
      <div className="py-14 md:py-28 bg-green-linear-gradient">
        <Slider {...slickSettings}>
          {t('testimonials', { returnObjects: true }).map(
            (testimonial: TestimonialProps, index: number) => (
              <div key={index} className="custom-slick-slide">
                <p className="mb-8 text-black">{testimonial.description}</p>
                <div className="flex flex-row justify-between items-end">
                  <div>
                    <p className="text-black font-bold">{testimonial.name}</p>
                    <p className="text-black italic">
                      {testimonial.position + ', ' + testimonial.company}
                    </p>
                  </div>
                  <div>
                    <LazyLoadImage
                      src={require('src/client/assets/images/quotation-mark.webp')}
                      alt={t('quotationMark')}
                      className="w-full max-w-[80px] h-auto"
                    />
                  </div>
                </div>
              </div>
            ),
          )}
        </Slider>
      </div>
    </section>
  );
};

export default TheySaidAboutUs;
