import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { UilTimes } from '@iconscout/react-unicons';

import { VideoPlayerProps } from 'src/client/interfaces/videoPlayer.interface';

import './VideoPlayer.scss';

const VideoPlayer = ({ open, onClick, src }: VideoPlayerProps) => {
  const videoPlayerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    videoPlayerRef.current?.seekTo(0);
  }, [open]);

  return (
    <div id="video-player">
      {open ? (
        <div
          className="fixed top-0 bottom-0 left-0 right-0 w-screen h-screen bg-black/75 z-[1001]"
          onClick={onClick}
        ></div>
      ) : null}
      <div className={`modal ${open ? 'modal-open' : 'modal-close'}`}>
        <div
          className="absolute -top-8 right-0 cursor-pointer"
          onClick={onClick}
        >
          <UilTimes className="w-8 h-8 text-[#dbdbdb]" />
        </div>
        <ReactPlayer
          ref={videoPlayerRef}
          url={src ? require('src/client/assets/videos/' + src) : ''}
          width="100%"
          height="100%"
          playing={open}
          controls
          progressInterval={1000}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
