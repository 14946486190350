import React from 'react';

import { PaginationButtonProps } from 'src/client/types/pagination';

const PaginationButton: React.FC<PaginationButtonProps> = ({
  content,
  onClick,
  active,
  disabled,
  label,
}: PaginationButtonProps) => {
  return (
    <button
      className={`flex flex-col justify-center items-center p-0 w-10 h-10 btn ${active ? 'btn-green' : 'bg-white'} ${!disabled ? 'bg-white hover:text-white hover:bg-green-600' : 'cursor-not-allowed'}`}
      onClick={onClick}
      disabled={disabled}
      aria-label={label}
    >
      {content}
    </button>
  );
};

export default PaginationButton;
