import { Language } from 'src/client/enums/language.enum';

import { PostProps } from 'src/client/interfaces/post.interface';

import { RESET_POST, SET_POST, SET_POST_ERROR } from './constants';
import { PostAction, PostState } from './types';

const initialState: PostState = {
  data: {
    _id: '',
    title: '',
    content: null,
    postImageFile: '',
    tags: [],
    lang: Language.HU,
    authorFirstName: '',
    authorLastName: '',
    status: 0,
    createdAt: null,
    updatedAt: null,
  },
  error: '',
};

export default (
  state: PostState = initialState,
  action: PostAction,
): PostState => {
  switch (action.type) {
    case SET_POST:
      return {
        ...state,
        data: {
          ...state.data,
          ...(action.payload as PostProps),
        },
      };
    case SET_POST_ERROR:
      return {
        ...state,
        error: action.payload as string,
      };
    case RESET_POST:
      return initialState;
    default:
      return state;
  }
};
