import { EmailProps } from 'src/client/interfaces/email.interface';
import { ScrollToElementProps } from 'src/client/interfaces/scrollToElement.interface';
import { ScrollToTopProps } from 'src/client/interfaces/scrollToTop.interface';

export const isServer: boolean = !(
  typeof window != 'undefined' && window.document
);

export const makeEmail = ({ user, domain }: EmailProps) => user + '@' + domain;

export const scrollToTop = ({ behavior = 'auto' }: ScrollToTopProps) => {
  window.scrollTo({ top: 0, behavior: behavior });
};

export const scrollToElement = ({
  elementId,
  top = 120,
}: ScrollToElementProps) => {
  const element = document.getElementById(elementId);

  if (element) {
    window.scrollTo({
      top: element.offsetTop - top,
      behavior: 'smooth',
    });
  }
};
