import React from 'react';
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/blur-up/ls.blur-up';

import './LazyLoadImage.scss';

lazysizes.cfg.blurupMode = 'blurred-image';

interface LazyLoadImageProps {
  src: string;
  alt: string;
  title?: string;
  className?: string;
}

const LazyLoadImage = ({
  src,
  alt = '',
  title = '',
  className = '',
}: LazyLoadImageProps) => (
  <img
    data-src={src}
    alt={alt}
    title={title}
    className={`${className} lazyload blur-up`}
  />
);

export default LazyLoadImage;
