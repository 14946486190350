import React from 'react';
import { Fancybox } from '@fancyapps/ui';

import { ImageProps } from 'src/client/types/image';

import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

const images: ImageProps[] = [
  {
    src: 'mindset-shaping-training-photo-001.webp',
    title: 'Hír TV Radar werkfotó 001',
  },
  {
    src: 'mindset-shaping-training-photo-002.webp',
    title: 'Hír TV Radar werkfotó 002',
  },
  {
    src: 'mindset-shaping-training-photo-003.webp',
    title: 'Hír TV Radar werkfotó 003',
  },
  {
    src: 'mindset-shaping-training-photo-004.webp',
    title: 'Hír TV Radar werkfotó 004',
  },
  {
    src: 'mindset-shaping-training-photo-005.webp',
    title: 'Hír TV Radar werkfotó 005',
  },
  {
    src: 'mindset-shaping-training-photo-006.webp',
    title: 'Hír TV Radar werkfotó 006',
  },
  {
    src: 'mindset-shaping-training-photo-007.webp',
    title: 'Hír TV Radar werkfotó 007',
  },
];

Fancybox.bind('[data-fancybox="mindset-shaping-training"]');

const Gallery: React.FC = () => (
  <section id="gallery" className="pt-20">
    <div className="container">
      <div className="grid grid-cols-3 gap-4">
        {images.map((image: ImageProps, index: number) => (
          <FadeInAnimation
            key={index}
            direction="down"
            className={index === 0 ? 'col-span-full' : ''}
          >
            <a
              data-fancybox="mindset-shaping-training"
              href={
                image.src
                  ? require('src/client/assets/images/' + image.src)
                  : ''
              }
              className="w-full h-full rounded-3xl overflow-hidden custom-shadow-hard"
            >
              <LazyLoadImage
                src={
                  image.src
                    ? require('src/client/assets/images/' + image.src)
                    : ''
                }
                alt={image.title}
                className="w-full h-full object-cover duration-300 hover:scale-105"
              />
            </a>
          </FadeInAnimation>
        ))}
      </div>
    </div>
  </section>
);

export default Gallery;
