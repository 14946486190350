import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { History } from 'history';

import { GeolocationProps } from './interfaces/geolocation.interface';

import { Dispatch } from 'src/client/index';

import {
  setGeolocation,
  setGeolocationError,
} from 'src/client/store/geolocation/actions';

import renderRoutes from 'src/client/routes/RenderRoutes';

import PageLoader from 'src/client/components/PageLoader/PageLoader';
import GoogleAnalytics from 'src/client/components/GoogleAnalytics/GoogleAnalytics';
import GoogleTagManager from 'src/client/components/GoogleTagManager/GoogleTagManager';
import Header from 'src/client/components/Header/Header';
import Footer from 'src/client/components/Footer/Footer';
import CookieConsent from 'src/client/components/CookieConsent/CookieConsent';

interface AppProps {
  history: History;
}

const App: React.FC<AppProps> = ({ history }: AppProps) => {
  const dispatch: Dispatch = useDispatch();

  const getClientGeolocation = async () => {
    try {
      const response: AxiosResponse = await axios.get(
        process.env.REACT_APP_GEOLOCATION_URL +
          process.env.REACT_APP_GEOLOCATION_TOKEN,
      );
      const geolocation: GeolocationProps = response.data;

      if (geolocation) {
        dispatch(setGeolocation(geolocation));
        dispatch(setGeolocationError(''));
      }
    } catch (error) {
      const err = error as AxiosError;

      if (err.message) {
        dispatch(
          setGeolocation({
            ip: '',
            hostname: '',
            org: '',
            country: '',
            region: '',
            city: '',
            postal: '',
            loc: '',
            timezone: '',
          }),
        );
        dispatch(setGeolocationError(err.message));
      }
    }
  };

  useEffect(() => {
    getClientGeolocation();
  }, []);

  return (
    <Suspense fallback={<PageLoader />}>
      {GoogleAnalytics.init() && (
        <GoogleAnalytics.RouteTracker location={history.location} />
      )}
      <GoogleTagManager />
      <Header />
      <main>{renderRoutes({})}</main>
      <Footer />
      <CookieConsent />
    </Suspense>
  );
};

export default App;
