import { RESET_SEARCH_FILTER, SET_SEARCH_FILTER } from './constants';

import { SearchFilterAction, SearchFilterState } from './types';

const initialState: SearchFilterState = {
  searchText: '',
  page: 1,
  limit: 10,
};

export default (
  state: SearchFilterState = initialState,
  action: SearchFilterAction,
): SearchFilterState => {
  switch (action.type) {
    case SET_SEARCH_FILTER:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_SEARCH_FILTER:
      return initialState;
    default:
      return state;
  }
};
