import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageProps } from 'src/client/types/image';

import { history } from 'src/client/store/configureStore';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import Contact from 'src/client/components/Contact/Contact';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import Partners from 'src/client/components/Partners/Partners';
import TheySaidAboutUs from 'src/client/components/TheySaidAboutUs/TheySaidAboutUs';

import Intro from './Intro/Intro';
import Services from './Services/Services';

const partners: ImageProps[] = [
  {
    src: 'lufthansa.webp',
    title: 'Lufthansa',
  },
  {
    src: 'prezi.webp',
    title: 'Prezi',
  },
  {
    src: 'sap.webp',
    title: 'SAP',
  },
  {
    src: 'skilly.webp',
    title: 'Skilly',
  },
  {
    src: 'raab-software.webp',
    title: 'RAAB SOFTWARE',
  },
  {
    src: 'szikra.webp',
    title: 'Szikra Coworking',
  },
  {
    src: 'hekate-foundation.webp',
    title: 'Hekate Foundation',
  },
  {
    src: 'inflexion.webp',
    title: 'Inflexion',
  },
  {
    src: 'liber-primus-studio.webp',
    title: 'Liber Primus Studio',
  },
  {
    src: 'punch-projects.webp',
    title: 'Punch Projects',
  },
  {
    src: 'skool.webp',
    title: 'Skool',
  },
  {
    src: 'smartcloud-digital.webp',
    title: 'SmartCloud Digital',
  },
  {
    src: 'ux-studio.webp',
    title: 'UX Studio',
  },
  {
    src: 'codeberry.webp',
    title: 'Codeberry School',
  },
  {
    src: 'innosign.webp',
    title: 'Innosign',
  },
  {
    src: 'equalizent.webp',
    title: 'Equalizent',
  },
];

const Home: React.FC = () => {
  const { t } = useTranslation('global');

  const { hash } = history.location;

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1) });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="pt-28 bg-right-side bg-right-top">
      <PageTitle />
      <Intro />
      <Services />
      <TheySaidAboutUs />
      <Partners title={t('ourPartners')} partners={partners} />
      <Contact />
    </div>
  );
};

export default Home;
