import { lazy } from 'react';

import { RouteProps } from 'src/client/types/route';

export const routes: RouteProps[] = [
  {
    path: '/',
    element: lazy(() => import('src/client/pages/Home/Home')),
  },
  {
    path: '/impact-pledge',
    element: lazy(() => import('src/client/pages/ImpactPledge/ImpactPledge')),
  },
  {
    path: '/sign-language',
    element: lazy(() => import('src/client/pages/SignLanguage/SignLanguage')),
  },
  {
    path: '/about',
    element: lazy(() => import('src/client/pages/About/About')),
  },
  {
    path: '/webshop',
    element: lazy(() => import('src/client/pages/Webshop/Webshop')),
  },
  {
    path: '/mindset-shaping-training',
    element: lazy(
      () =>
        import(
          'src/client/pages/MindsetShapingTraining/MindsetShapingTraining'
        ),
    ),
  },
  {
    path: '/software-development',
    element: lazy(
      () => import('src/client/pages/SoftwareDevelopment/SoftwareDevelopment'),
    ),
  },
  {
    path: '/design',
    element: lazy(() => import('src/client/pages/Design/Design')),
  },
  {
    path: '/digital-knowledge-dissemination',
    element: lazy(
      () =>
        import(
          'src/client/pages/DigitalKnowledgeDissemination/DigitalKnowledgeDissemination'
        ),
    ),
  },
  {
    path: '/digital-knowledge-dissemination/diginclude',
    element: lazy(
      () =>
        import(
          'src/client/pages/DigitalKnowledgeDissemination/DigInclude/DigIncude'
        ),
    ),
  },
  {
    path: '/blog',
    element: lazy(() => import('src/client/pages/Blog/Blog')),
  },
  {
    path: '/post/:id',
    element: lazy(() => import('src/client/pages/Post/Post')),
  },
  {
    path: '/privacy-policy',
    element: lazy(() => import('src/client/pages/PrivacyPolicy/PrivacyPolicy')),
  },
  {
    path: '/assets/*',
    element: lazy(() => import('src/client/pages/Forbidden/Forbidden')),
  },
  {
    path: '*',
    element: lazy(() => import('src/client/pages/PageNotFound/PageNotFound')),
  },
];
