import React, { FormEvent, Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { isIOS } from 'react-device-detect';
import EditorJSRenderer from 'editorjs-react-renderer';

import { ApiResponse } from 'src/client/types/api-response';
import { PostsState } from 'src/client/store/posts/types';
import { SearchFilterState } from 'src/client/store/searchFilter/types';

import { Dispatch, RootState } from 'src/client/index';

import {
  resetPosts,
  setPosts,
  setPostsError,
} from 'src/client/store/posts/actions';
import {
  resetSearchFilter,
  setSearchFilter,
} from 'src/client/store/searchFilter/actions';

import { usePrevious } from 'src/client/hooks/usePrevious';

import { scrollToTop } from 'src/client/helpers/globals';

import { PostProps, PostsProps } from 'src/client/interfaces/post.interface';

import Contact from 'src/client/components/Contact/Contact';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import Pagination from 'src/client/components/Pagination/Pagination';
import PlayIcon from 'src/client/components/PlayIcon/PlayIcon';
import VideoPlayer from 'src/client/components/VideoPlayer/VideoPlayer';

import './Blog.scss';

const Blog: React.FC = () => {
  const { t, i18n } = useTranslation('blog');

  const dispatch: Dispatch = useDispatch();
  const posts: PostsState = useSelector((state: RootState) => state.posts);
  const searchFilter: SearchFilterState = useSelector(
    (state: RootState) => state.searchFilter,
  );

  const [openVideo, setOpenVideo] = useState<boolean>(false);
  const [videoSrc, setVideoSrc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const previousLanguage = usePrevious<string>(i18n.language);

  useEffect(() => {
    scrollToTop({});
  }, []);

  useEffect(() => {
    dispatch(resetPosts());
  }, [dispatch, i18n.language]);

  useEffect(() => {
    if (i18n.language !== previousLanguage) {
      dispatch(resetSearchFilter());
    }
  }, [dispatch, i18n.language, posts, searchFilter]);

  useEffect(() => {
    getPosts();
  }, [i18n.language, searchFilter]);

  const getPosts = async () => {
    try {
      setLoading(true);

      const response: AxiosResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_API_URL}/public/posts`,
        {
          params: {
            lang: i18n.language,
            searchText: searchFilter.searchText,
            page: searchFilter.page,
            limit: searchFilter.limit,
          },
        },
      );
      const responseData: ApiResponse = response.data;

      if (responseData) {
        const postsData: PostsProps = responseData.data;

        dispatch(setPosts(postsData));
        dispatch(setPostsError(''));
      }

      return;
    } catch (error) {
      const err = error as AxiosError;

      if (err.message) {
        dispatch(setPosts({ data: [], totalCount: 0 }));
        dispatch(setPostsError(err.message));
      }

      return;
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (event: FormEvent) => {
    const { value } = event.currentTarget as HTMLInputElement;

    dispatch(
      setSearchFilter({
        searchText: value,
        page: 1,
        limit: searchFilter.limit,
      }),
    );
  };

  return (
    <div id="blog" className="pt-12 lg:pt-20">
      <PageTitle title={t('title')} />
      <VideoPlayer
        open={openVideo}
        onClick={() => setOpenVideo(false)}
        src={videoSrc}
      />
      <div className="bg-right-side bg-right-top">
        <section id="video" className="pt-20">
          <div className="container">
            <div
              className="relative rounded-3xl cursor-pointer overflow-hidden z-0 custom-shadow-hard"
              onClick={() => {
                setOpenVideo(true);
                setVideoSrc(
                  'sign-language-hungarian-sign-language-day' +
                    (isIOS ? '.mp4' : '.webm'),
                );
              }}
            >
              <LazyLoadImage
                src={require('src/client/assets/images/sign-language-video-thumbnail-001.webp')}
                alt={t('hungarianSignLanguageDay')}
                className="w-full"
              />
              <div className="absolute top-0 bottom-0 left-0 right-0 z-10 overlay-video"></div>
              <PlayIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20" />
            </div>
          </div>
        </section>
        <section id="latest-blog-posts" className="pt-20">
          <div className="container">
            <h1 className="page-title-hidden">{t('title')}</h1>
            <h2>{t('latestBlogPosts')}</h2>
          </div>
          <div className="py-12 bg-green-linear-gradient">
            <div className="container">
              <div className="relative">
                <input
                  type="text"
                  name="searchText"
                  id="searchText"
                  className="block w-full pl-8 md:pl-12 pr-12 md:pr-16 py-4 text-white bg-transparent border-0 border-b-2 border-white focus:border-white ring-0 focus:ring-0"
                  placeholder={t('searchPlaceholder')}
                  aria-label={t('searchPlaceholder')}
                  value={searchFilter.searchText}
                  onChange={handleSearchInputChange}
                />
                <div className="absolute inset-y-0 end-0 flex items-center px-4 md:px-8 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-20">
            {loading ? (
              <div className="text-center">
                <svg
                  className="inline-block mb-0.5 -ml-1 mr-3 h-5 w-5 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : posts.data.length > 0 ? (
              <>
                {posts.data.map((post: PostProps, index: number) => (
                  <Fragment key={index}>
                    <div className="flex flex-col lg:flex-row post-card">
                      {post.postImageFile && (
                        <Link
                          to={`/post/${post._id}`}
                          className="w-full lg:w-1/3"
                        >
                          <LazyLoadImage
                            src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/posts/${post.postImageFile}`}
                            alt={post.title}
                            className="w-full h-full object-cover aspect-video"
                          />
                        </Link>
                      )}
                      <div
                        className={`flex flex-col px-4 py-8 md:px-8 ${post.postImageFile ? 'w-full lg:w-2/3' : 'w-full'}`}
                      >
                        <h3 className="text-lg md:text-xl">
                          <Link
                            to={`/post/${post._id}`}
                            className="line-clamp-2"
                          >
                            {post.title}
                          </Link>
                        </h3>
                        <div className="mb-4 lg:mb-8 line-clamp-2 lg:line-clamp-3 post-content">
                          <EditorJSRenderer data={post.content} />
                        </div>
                        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
                          <div className="inline-block mb-4 md:mb-8 lg:mb-0 max-w-full lg:max-w-xl text-white overflow-hidden whitespace-nowrap text-ellipsis">
                            {post.tags &&
                              post.tags.map((tag: string, index: number) => (
                                <span
                                  key={index}
                                  className="inline-block mr-2 text-white"
                                >
                                  #{tag}
                                </span>
                              ))}
                          </div>
                          <div className="text-right">
                            <Link
                              to={`/post/${post._id}`}
                              className="btn btn-green"
                            >
                              {t('readMore')}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
                <Pagination
                  goToPage={(page: number) => {
                    dispatch(
                      setSearchFilter({
                        searchText: searchFilter.searchText,
                        page: page,
                        limit: searchFilter.limit,
                      }),
                    );
                  }}
                  page={searchFilter.page}
                  limit={searchFilter.limit}
                  totalCount={posts.totalCount}
                />
              </>
            ) : (
              <p className="text-center">{t('noPostsAvailable')}</p>
            )}
          </div>
        </section>
      </div>
      <Contact />
    </div>
  );
};

export default Blog;
