import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  UisAngleDoubleLeft,
  UisAngleDoubleRight,
} from '@iconscout/react-unicons-solid';

import { PaginationProps } from 'src/client/types/pagination';

import PaginationButton from './PaginationButton';

const Pagination: React.FC<PaginationProps> = ({
  goToPage,
  page,
  limit,
  totalCount,
}: PaginationProps) => {
  const { t } = useTranslation('global');

  const current: number = page;
  const last: number = Math.ceil(totalCount / limit);
  const delta: number = 2;
  const left: number = current - delta;
  const right: number = current + delta + 1;
  const range: number[] = [];
  const rangeWithDots: any[] = [];
  let l: number = 0;

  const renderPageLinks = () => {
    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots.map((pageNumber: string | number, index: number) => (
      <li key={index}>
        {typeof pageNumber === 'string' ? (
          <span className="flex flex-col justify-end items-center w-10 h-10 text-white">
            {pageNumber}
          </span>
        ) : (
          <PaginationButton
            content={pageNumber}
            onClick={() => goToPage(pageNumber)}
            active={page === pageNumber}
            disabled={page === pageNumber}
          />
        )}
      </li>
    ));
  };

  return (
    <ul className="flex flex-row justify-center gap-2">
      <li>
        <PaginationButton
          content={<UisAngleDoubleLeft />}
          onClick={() => goToPage(1)}
          disabled={!(page > 1)}
          label={t('previous')}
        />
      </li>

      {renderPageLinks()}

      <li>
        <PaginationButton
          content={<UisAngleDoubleRight />}
          onClick={() => goToPage(last)}
          disabled={page === last}
          label={t('next')}
        />
      </li>
    </ul>
  );
};

export default Pagination;
