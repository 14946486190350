import { RESET_POSTS, SET_POSTS, SET_POSTS_ERROR } from './constants';

import { PostsProps } from 'src/client/interfaces/post.interface';

import { PostsAction } from './types';

export const setPosts = (payload: PostsProps): PostsAction => {
  return {
    type: SET_POSTS,
    payload,
  };
};

export const setPostsError = (payload: string): PostsAction => {
  return {
    type: SET_POSTS_ERROR,
    payload,
  };
};

export const resetPosts = (): PostsAction => {
  return {
    type: RESET_POSTS,
  };
};
