import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReferenceProps } from 'src/client/interfaces/reference.interface';

import { ImageProps } from 'src/client/types/image';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import { history } from 'src/client/store/configureStore';

import LandingIntro from 'src/client/components/LandingIntro/LandingIntro';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import References from 'src/client/components/References/References';
import Stacks from 'src/client/components/Stacks/Stacks';
import WhatAreWeCommittingTo from 'src/client/components/WhatAreWeCommittingTo/WhatAreWeCommittingTo';
import Contact from 'src/client/components/Contact/Contact';

const references: ReferenceProps[] = [
  {
    src: 'pinger.webp',
    title: 'Pinger',
    logo: 'pinger-logo.webp',
  },
  {
    src: 'sounds-of-silence.webp',
    title: 'Sounds Of Silence',
    logo: 'sounds-of-silence-logo.webp',
  },
  {
    src: 'dm.webp',
    title: 'DM',
    logo: 'dm-logo.webp',
  },
  {
    src: 'obi.webp',
    title: 'OBI',
    logo: 'obi-logo.webp',
  },
  {
    src: 'misco.webp',
    title: 'Misco',
    logo: 'misco-logo.webp',
  },
];

const stacks: ImageProps[] = [
  {
    src: 'figma.svg',
    title: 'Figma',
  },
  {
    src: 'adobe-photoshop.svg',
    title: 'Adobe Photoshop',
  },
  {
    src: 'adobe-illustrator.svg',
    title: 'Adobe Illustrator',
  },
  {
    src: 'adobe-indesign.svg',
    title: 'Adobe Indesign',
  },
  {
    src: 'adobe-after-effects.svg',
    title: 'Adobe After Effects',
  },
  {
    src: 'adobe-lightroom.svg',
    title: 'Adobe Lightroom',
  },
];

const Design: React.FC = () => {
  const { t } = useTranslation(['global', 'design']);

  const { hash } = history.location;

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1) });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="design">
      <PageTitle title={t('pageTitle', { ns: 'design' })} />
      <LandingIntro
        title={t('title', { ns: 'design' })}
        paragraph={t('checkOurReferencesAndLetsWorkTogether', { ns: 'global' })}
        backgroundImageSrc="design-cover.webp"
        illustrationImageSrc="design-illustration.webp"
      />
      <div className="bg-right-side bg-right-top">
        <References references={references} />
        <Stacks title={t('skills')} stacks={stacks} />
        <WhatAreWeCommittingTo />
      </div>
      <Contact />
    </div>
  );
};

export default Design;
