import React from 'react';

import { ImageProps } from 'src/client/types/image';

import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

const Photo: React.FC<ImageProps> = ({ src, title }: ImageProps) => (
  <section id="photo">
    <div className="container">
      <FadeInAnimation direction="down">
        <LazyLoadImage
          src={src ? require('src/client/assets/images/' + src) : ''}
          alt={title}
          className="w-full h-full object-cover rounded-3xl duration-300 custom-shadow-hard"
        />
      </FadeInAnimation>
    </div>
  </section>
);

export default Photo;
