import React, { TouchEvent, useEffect, useState } from 'react';

import { ImageProps } from 'src/client/types/image';

import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

import './Slideshow.scss';

const images: ImageProps[] = [
  {
    src: 'allmyles.webp',
    title: 'Allmyles',
  },
  {
    src: 'wasabi.webp',
    title: 'Wasabi',
  },
  {
    src: 'loci-es-bibe-jelel.webp',
    title: 'Lóci és Bibe jelel',
  },
  {
    src: 'obi.webp',
    title: 'OBI',
  },
];

const Slideshow: React.FC = () => {
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  useEffect(() => {
    const timer: string | number | NodeJS.Timeout | undefined = setTimeout(
      () =>
        setSlideIndex((prevSlideIndex) =>
          prevSlideIndex === images.length - 1 ? 0 : prevSlideIndex + 1,
        ),
      5000,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [slideIndex]);

  const handleTouchStart = (event: TouchEvent) => {
    const touchDown: number = event.touches[0].clientX;

    setTouchPosition(touchDown);
  };

  const handleTouchMove = (event: TouchEvent) => {
    const touchDown: number | null = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch: number = event.touches[0].clientX;
    const diff: number = touchDown - currentTouch;

    if (diff > 5) {
      setSlideIndex((prevSlideIndex) =>
        prevSlideIndex === images.length - 1 ? 0 : prevSlideIndex + 1,
      );
    }

    if (diff < -5) {
      setSlideIndex((prevSlideIndex) =>
        prevSlideIndex === 0 ? images.length - 1 : prevSlideIndex - 1,
      );
    }

    setTouchPosition(null);
  };

  return (
    <div className="slideshow custom-shadow-hard">
      <div
        className="slides"
        style={{ transform: `translate3d(${-slideIndex * 100}%, 0, 0)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {images.map((image: ImageProps, index) => (
          <div key={index} className="slide">
            <LazyLoadImage
              src={
                image.src
                  ? require('src/client/assets/images/' + image.src)
                  : ''
              }
              alt={image.title}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>

      <div className="dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${slideIndex === index ? 'active' : ''}`}
            onClick={() => setSlideIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
