import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditorJSRenderer from 'editorjs-react-renderer';
import moment from 'moment';

import { ApiResponse } from 'src/client/types/api-response';
import { PostState } from 'src/client/store/post/types';

import { Language } from 'src/client/enums/language.enum';

import { Dispatch, RootState } from 'src/client/index';

import {
  resetPost,
  setPost,
  setPostError,
} from 'src/client/store/post/actions';

import { scrollToTop } from 'src/client/helpers/globals';

import { PostProps } from 'src/client/interfaces/post.interface';

import Contact from 'src/client/components/Contact/Contact';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';
import PageTitle from 'src/client/components/PageTitle/PageTitle';

import './Post.scss';

const Post: React.FC = () => {
  const { t, i18n } = useTranslation('post');

  const { id } = useParams();

  const dispatch: Dispatch = useDispatch();
  const post: PostState = useSelector((state: RootState) => state.post);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    scrollToTop({});
  }, []);

  useEffect(() => {
    dispatch(resetPost());
  }, [dispatch, i18n.language]);

  useEffect(() => {
    getPost();
  }, [i18n.language]);

  const getPost = async () => {
    setLoading(true);

    try {
      const response: AxiosResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_API_URL}/public/posts/${id}`,
        { params: { lang: i18n.language } },
      );
      const responseData: ApiResponse = response.data;

      if (responseData) {
        const postData: PostProps = responseData.data;

        dispatch(setPost(postData));
        dispatch(setPostError(''));
      }
    } catch (error) {
      const err = error as AxiosError;

      if (err.message) {
        dispatch(
          setPost({
            _id: '',
            title: '',
            content: null,
            postImageFile: '',
            tags: [],
            lang: Language.HU,
            author: '',
            status: 0,
            createdAt: null,
            updatedAt: null,
          }),
        );
        dispatch(setPostError(err.message));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="post" className="pt-12 lg:pt-20">
      <PageTitle title={post.data.title || t('noPostAvailable')} />
      <section className="pt-20">
        <div className="container">
          {loading ? (
            <div className="text-center">
              <svg
                className="inline-block mb-0.5 -ml-1 mr-3 h-5 w-5 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : post.data._id ? (
            <>
              <h1 className="mb-4">{post.data.title}</h1>
              <div className="mb-8 text-white">
                {i18n.language === 'hu' ? (
                  <>
                    <span className="text-green-400">
                      {post.data.authorLastName}
                    </span>{' '}
                    {post.data.authorFirstName}
                  </>
                ) : (
                  <>
                    {post.data.authorFirstName}{' '}
                    <span className="text-green-400">
                      {post.data.authorLastName}
                    </span>
                  </>
                )}
                <span className="mx-2">|</span>
                {moment(post.data.createdAt)
                  .locale(i18n.language)
                  .format('LLL')}
                {post.data.tags.length > 0 && (
                  <>
                    <span className="mx-2">|</span>
                    {post.data.tags.map((tag: string, index: number) => (
                      <span key={index} className="inline-block mr-2">
                        #{tag}
                      </span>
                    ))}
                  </>
                )}
              </div>
              {post.data.postImageFile && (
                <LazyLoadImage
                  src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/posts/${post.data.postImageFile}`}
                  alt={post.data.title}
                  className="w-full h-full aspect-video object-cover rounded-3xl duration-300 custom-shadow-hard"
                />
              )}
              <div id="post-content">
                <EditorJSRenderer data={post.data.content} />
              </div>
            </>
          ) : (
            <>
              <h1 className="page-title-hidden">{t('noPostAvailable')}</h1>
              <p className="text-center">{t('noPostAvailable')}</p>
            </>
          )}
        </div>
      </section>
      <Contact />
    </div>
  );
};

export default Post;
