import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactCookieConsent from 'react-cookie-consent';

const CookieConsent = () => {
  const { t } = useTranslation('cookie-consent');

  const gtag = (...args: any[]) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(args);
  };

  const handleDecision = (outcome: string) => {
    const consent = {
      ad_storage: outcome,
      analytics_storage: outcome,
      ad_user_data: outcome,
      ad_personalization: outcome,
    };

    gtag('consent', 'default', consent);
  };

  return (
    <ReactCookieConsent
      location="bottom"
      style={{ color: '#fff', backgroundColor: '#353535' }}
      enableDeclineButton
      extraCookieOptions={{ domain: process.env.REACT_APP_DOMAIN }}
      expires={new Date(
        new Date().setFullYear(new Date().getFullYear() + 1),
      ).getTime()}
      contentStyle={{
        margin: '16px',
      }}
      buttonStyle={{
        margin: '16px',
        padding: '6px 18px',
        color: '#fff',
        backgroundColor: '#69bc8d',
      }}
      declineButtonStyle={{
        margin: '16px',
        marginRight: 0,
        padding: '6px 18px',
        color: '#fff',
        backgroundColor: '#dc2626',
      }}
      buttonText={t('acceptButtonText')}
      declineButtonText={t('declineButtonText')}
      onAccept={() => handleDecision('granted')}
      onDecline={() => handleDecision('denied')}
    >
      {t('text')} <Link to="/privacy-policy">{t('privacyPolicy')}</Link>
    </ReactCookieConsent>
  );
};

export default CookieConsent;
