import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  UilArrowUp,
  UilEnvelope,
  UilFacebookF,
  UilInstagram,
  UilLinkedinAlt,
  UilMapMarker,
  UilMobileAndroid,
} from '@iconscout/react-unicons';

import {
  makeEmail,
  scrollToElement,
  scrollToTop,
} from 'src/client/helpers/globals';

import './Footer.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation('footer');

  return (
    <footer className="py-16 bg-black">
      <div className="container">
        <div className="flex flex-col lg:flex-row links">
          <div className="flex-1 mb-8 lg:mb-0 px-4 md:px-8 lg:border-l-2 border-green-400">
            <div className="mb-2 text-green-400 text-lg leading-7 md:text-xl md:leading-9 font-medium">
              {t('services')}
            </div>
            <ul>
              <li>
                <NavLink to="/software-development">
                  {t('softwareDevelopment')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/webshop">{t('webshop')}</NavLink>
              </li>
              <li>
                <NavLink to="/design">{t('design')}</NavLink>
              </li>
              <li>
                <NavLink to="/mindset-shaping-training">
                  {t('mindsetShapingTraining')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/digital-knowledge-dissemination">
                  {t('digitalKnowledgeDissemination')}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="flex-1 mb-8 lg:mb-0 px-4 md:px-8 lg:border-x-2 border-green-400">
            <div className="mb-2 text-green-400 text-lg leading-7 md:text-xl md:leading-9 font-medium">
              {t('impactPledge')}
            </div>
            <ul>
              <li>
                <Link
                  to="/impact-pledge#our-mission"
                  onClick={() =>
                    scrollToElement({ elementId: 'our-mission', top: 30 })
                  }
                >
                  {t('mission')}
                </Link>
              </li>
              <li>
                <Link
                  to="/impact-pledge#challenge"
                  onClick={() =>
                    scrollToElement({ elementId: 'challenge', top: 30 })
                  }
                >
                  {t('challenge')}
                </Link>
              </li>
              <li>
                <Link
                  to="/impact-pledge#we-build-on-our-strengths"
                  onClick={() =>
                    scrollToElement({
                      elementId: 'we-build-on-our-strengths',
                      top: 30,
                    })
                  }
                >
                  {t('weBuildOnStrengths')}
                </Link>
              </li>
              <li>
                <Link
                  to="/impact-pledge#social-inclusion"
                  onClick={() =>
                    scrollToElement({ elementId: 'social-inclusion', top: 30 })
                  }
                >
                  {t('socialInclusion')}
                </Link>
              </li>
              <li>
                <Link
                  to="/impact-pledge#trainee-education"
                  onClick={() =>
                    scrollToElement({
                      elementId: 'trainee-education',
                      top: 30,
                    })
                  }
                >
                  {t('traineeEducation')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-1 mb-8 lg:mb-0 px-4 md:px-8 lg:border-r-2 border-green-400 contacts">
            <div className="mb-2 text-green-400 text-lg leading-7 md:text-xl md:leading-9 font-medium">
              {t('getInTouchWithUs')}
            </div>
            <ul className="mb-6">
              <li className="flex items-center">
                <UilEnvelope className="flex-[0_0_1.5rem] mr-3 w-6 text-green-400" />
                <a
                  href={
                    'mailto:' +
                    makeEmail({ user: 'hello', domain: 'signcoders.hu' })
                  }
                >
                  {makeEmail({ user: 'hello', domain: 'signcoders.hu' })}
                </a>
              </li>
              <li className="flex items-center">
                <UilMobileAndroid className="flex-[0_0_1.5rem] mr-3 w-6 text-green-400" />
                <a href="tel:+36205112858">{t('phone')}</a>
              </li>
              <li className="flex items-center">
                <UilMapMarker className="flex-[0_0_1.5rem] mr-3 w-6 text-green-400" />
                <a
                  href="https://www.google.com/maps/place/Budapest,+Klauz%C3%A1l+u.+30,+1072/@47.4989698,19.062843,17z/data=!3m1!4b1!4m5!3m4!1s0x4741dc68649c712b:0xe425278d8148d7d!8m2!3d47.4989662!4d19.0650317"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('address')}
                </a>
              </li>
            </ul>
            <div className="flex flex-row items-center mb-4 social-media">
              <a
                href="https://www.facebook.com/signcodersteam/"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2 social-media-item"
                title="Facebook"
              >
                <UilFacebookF className="w-6" />
              </a>
              <a
                href="https://www.instagram.com/signcoders/"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3 social-media-item"
                title="Instagram"
              >
                <UilInstagram className="w-6" />
              </a>
              <a
                href="https://www.linkedin.com/company/signcoders"
                target="_blank"
                rel="noopener noreferrer"
                className="social-media-item"
                title="LinkedIn"
              >
                <UilLinkedinAlt className="w-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-8 px-4 md:px-8">
          <div className="flex flex-col md:flex-row items-start md:items-center">
            <small className="mb-3 md:mb-0 text-white">
              &copy; {new Date().getFullYear()} SignCoders{' '}
            </small>
            <span className="hidden md:inline-block mx-2 text-white text-xs">
              |
            </span>
            <Link to="/privacy-policy" className="text-xs">
              {t('privacyPolicy')}
            </Link>
          </div>
          <UilArrowUp
            onClick={() => scrollToTop({ behavior: 'smooth' })}
            className="ml-6 w-12 h-12 text-white hover:text-green-600 transition-all duration-300 cursor-pointer"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
