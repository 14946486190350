import React, { DragEvent, TouchEvent, useEffect, useState } from 'react';

import { PartnersProps } from 'src/client/interfaces/partner.interface';

import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

import './Partners.scss';

const Partners: React.FC<PartnersProps> = ({
  title = '',
  partners,
}: PartnersProps) => {
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  useEffect(() => {
    const timer: string | number | NodeJS.Timeout | undefined = setTimeout(
      () =>
        setSlideIndex((prevSlideIndex) =>
          prevSlideIndex ===
          (window.innerWidth < 768
            ? Math.ceil(partners.length / 2) - 1
            : Math.ceil(partners.length / 4) - 1)
            ? 0
            : prevSlideIndex + 1,
        ),
      5000,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [slideIndex]);

  const handleTouchStart = (event: TouchEvent) => {
    const touchDown: number = event.touches[0].clientX;

    setTouchPosition(touchDown);
  };

  const handleTouchMove = (event: TouchEvent) => {
    const touchDown: number | null = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch: number = event.touches[0].clientX;
    const diff: number = touchDown - currentTouch;

    if (diff > 5) {
      setSlideIndex((prevSlideIndex) =>
        prevSlideIndex ===
        (window.innerWidth < 768
          ? Math.ceil(partners.length / 2) - 1
          : Math.ceil(partners.length / 4) - 1)
          ? 0
          : prevSlideIndex + 1,
      );
    }

    if (diff < -5) {
      setSlideIndex((prevSlideIndex) =>
        prevSlideIndex === 0
          ? window.innerWidth < 768
            ? Math.ceil(partners.length / 2) - 1
            : Math.ceil(partners.length / 4) - 1
          : prevSlideIndex - 1,
      );
    }

    setTouchPosition(null);
  };

  const handleDragStart = (event: DragEvent) => {
    const touchDown: number = event.clientX;

    setTouchPosition(touchDown);
  };

  const handleDrag = (event: DragEvent) => {
    const touchDown: number | null = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch: number = event.clientX;
    const diff: number = touchDown - currentTouch;

    if (diff > 5) {
      setSlideIndex((prevSlideIndex) =>
        prevSlideIndex ===
        (window.innerWidth < 768
          ? Math.ceil(partners.length / 2) - 1
          : Math.ceil(partners.length / 4) - 1)
          ? 0
          : prevSlideIndex + 1,
      );
    }

    if (diff < -5) {
      setSlideIndex((prevSlideIndex) =>
        prevSlideIndex === 0
          ? window.innerWidth < 768
            ? Math.ceil(partners.length / 2) - 1
            : Math.ceil(partners.length / 4) - 1
          : prevSlideIndex - 1,
      );
    }

    setTouchPosition(null);
  };

  return (
    <section id="partners" className="pt-20">
      <div className="container">
        <h2>{title}</h2>
        <div className="carousel">
          <div
            className="slides"
            style={{ transform: `translate3d(${-slideIndex * 100}%, 0, 0)` }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onDragOver={handleDrag}
            onDragStart={handleDragStart}
          >
            {partners.map((image, index) => (
              <div key={index} className="slide">
                <LazyLoadImage
                  src={
                    image.src
                      ? require('src/client/assets/images/' + image.src)
                      : ''
                  }
                  alt={image.title}
                  className="w-auto max-w-[150px] lg:max-w-[200px] xl:max-w-[250px] h-auto"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
