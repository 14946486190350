import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PrivacyPolicyProps } from 'src/client/interfaces/privacy-policy.interface';

import { scrollToTop } from 'src/client/helpers/globals';

import Contact from 'src/client/components/Contact/Contact';
import PageTitle from 'src/client/components/PageTitle/PageTitle';

import './PrivacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation('privacy-policy');

  useEffect(() => {
    scrollToTop({});
  }, []);

  return (
    <div id="privacy-policy" className="pt-12 lg:pt-20">
      <PageTitle title={t('title')} />
      <section id="privacy-policy-content" className="pt-20">
        <div className="container">
          <h1>{t('title')}</h1>
          <p>{t('introduction.0')}</p>
          <p>
            {t('introduction.1')}{' '}
            <a href="https://www.gdprprivacynotice.com/">
              GDPR Privacy Policy Generator
            </a>
            .
          </p>
          {t('paragraph', { returnObjects: true }).map(
            (paragraph: PrivacyPolicyProps, index: number) => (
              <Fragment key={index}>
                <h2>{paragraph.title}</h2>
                {paragraph.texts &&
                  paragraph.texts.map((text: any, i: number) => (
                    <Fragment key={i}>
                      {text.lists ? (
                        <ul>
                          {text.lists.map((list: string, j: number) => (
                            <li key={j}>{list}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>{text}</p>
                      )}
                    </Fragment>
                  ))}
              </Fragment>
            ),
          )}
        </div>
      </section>
      <Contact />
    </div>
  );
};

export default PrivacyPolicy;
