import React from 'react';
import { useTranslation } from 'react-i18next';

const images: string[] = [
  'logo-and-brand-design.svg',
  'design.svg',
  'email-signature.svg',
  'web-graphics.svg',
  'mobile-app-design.svg',
  'ux-design.svg',
];

const WhatAreWeCommittingTo: React.FC = () => {
  const { t } = useTranslation('what-are-we-committing-to');

  return (
    <section id="what-are-we-committing-to" className="pt-20">
      <div className="container">
        <h2>{t('title')}</h2>
        <div className="grid md:grid-cols-2 gap-8 md:gap-12">
          {t('contents', { returnObjects: true }).map(
            (content: any, index: number) => (
              <div
                key={index}
                className="card mb-0 rounded-3xl custom-shadow-hard"
              >
                <img
                  src={
                    images[index]
                      ? require('src/client/assets/images/' + images[index])
                      : ''
                  }
                  alt={t(content.title)}
                  className="lg:float-right mx-auto mb-8 w-full max-w-[200px]"
                />
                <div className="flex flex-col lg:justify-end lg:pr-4 lg:h-full">
                  <h4 className="uppercase whitespace-pre-line">
                    {content.title}
                  </h4>
                  {content.lists &&
                    content.lists.map((list: string, index: number) => (
                      <li key={index} className="text-white">
                        {list}
                      </li>
                    ))}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </section>
  );
};

export default WhatAreWeCommittingTo;
