import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { RouteProps } from 'src/client/types/route';

import { routes } from 'src/client/routes/Routes';

import PageLoader from 'src/client/components/PageLoader/PageLoader';

const renderRoutes: React.FC = () => {
  return (
    <Routes>
      {routes.map((route: RouteProps, index: number) => {
        const Element = route.element as React.FC<any>;

        return (
          <Route
            key={index}
            index
            path={route.path}
            element={
              <Suspense fallback={<PageLoader />}>
                <Element />
              </Suspense>
            }
          />
        );
      })}
    </Routes>
  );
};

export default renderRoutes;
