import { RESET_SEARCH_FILTER, SET_SEARCH_FILTER } from './constants';

import { SearchFilterAction } from './types';
import { SearchFilterProps } from 'src/client/types/search-filter';

export const setSearchFilter = (
  payload: SearchFilterProps,
): SearchFilterAction => {
  return {
    type: SET_SEARCH_FILTER,
    payload,
  };
};

export const resetSearchFilter = (): SearchFilterAction => {
  return {
    type: RESET_SEARCH_FILTER,
  };
};
