import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'react-device-detect';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import { history } from 'src/client/store/configureStore';

import Contact from 'src/client/components/Contact/Contact';
import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import PlayIcon from 'src/client/components/PlayIcon/PlayIcon';
import Photo from 'src/client/components/Photo/Photo';
import VideoPlayer from 'src/client/components/VideoPlayer/VideoPlayer';

import FAQs from './FAQs/FAQs';

const SignLanguage: React.FC = () => {
  const { t } = useTranslation('sign-language');

  const [openVideo, setOpenVideo] = useState<boolean>(false);
  const [videoSrc, setVideoSrc] = useState<string>('');

  const { hash } = history.location;

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1), top: 20 });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="sign-language" className="pt-12 lg:pt-20">
      <PageTitle title={t('title')} />
      <VideoPlayer
        open={openVideo}
        onClick={() => setOpenVideo(false)}
        src={videoSrc}
      />
      <div className="pt-20 bg-both-side">
        <Photo src="sign-language-team-001.webp" title="SC team" />
        <section className="py-20">
          <div className="container">
            <h1 className="page-title-hidden">{t('title')}</h1>
            {t('experiences', { returnObjects: true }).map(
              (experience: any, index: number) => (
                <FadeInAnimation key={index} direction="down" className="card">
                  <h3>
                    {experience.titleOne}
                    <span className="text-green-400">
                      {experience.titleTwo}
                    </span>
                    {experience.titleThree}
                  </h3>
                  <p className="inline">{experience.text}</p>
                </FadeInAnimation>
              ),
            )}
          </div>
        </section>
        <section id="video" className="pb-20">
          <div className="container">
            <div
              className="relative rounded-3xl cursor-pointer overflow-hidden z-0 custom-shadow-hard"
              onClick={() => {
                setOpenVideo(true);
                setVideoSrc(
                  'sign-language-hungarian-sign-language-day' +
                    (isIOS ? '.mp4' : '.webm'),
                );
              }}
            >
              <LazyLoadImage
                src={require('src/client/assets/images/sign-language-video-thumbnail-001.webp')}
                alt={t('hungarianSignLanguageDay')}
                className="w-full"
              />
              <div className="absolute top-0 bottom-0 left-0 right-0 z-10 overlay-video"></div>
              <PlayIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20" />
            </div>
          </div>
        </section>
        <FAQs />
        <Photo src="sign-language-team-002.webp" title="SC team" />
      </div>
      <Contact />
    </div>
  );
};

export default SignLanguage;
