import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import {
  UisCheckCircle,
  UisExclamationCircle,
} from '@iconscout/react-unicons-solid';

import { ContactState } from 'src/client/store/contact/types';
import { GeolocationState } from 'src/client/store/geolocation/types';

import {
  setContact,
  setContactErrors,
  setContactTouched,
  setIsSentSuccessfully,
  setIsSentUnsuccessfully,
  resetContact,
} from 'src/client/store/contact/actions';

import { Dispatch, RootState } from 'src/client/index';

import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';

const subjects: string[] = [
  'priceQuote',
  'softwareDevelopment',
  'webshop',
  'design',
  'mindsetShapingTraining',
  'maintenance',
  'other',
];

const Contact: React.FC = () => {
  const { t } = useTranslation('contact');

  const dispatch: Dispatch = useDispatch();
  const contact: ContactState = useSelector(
    (state: RootState) => state.contact,
  );
  const geolocation: GeolocationState = useSelector(
    (state: RootState) => state.geolocation,
  );

  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (event: FormEvent) => {
    const { name, value } = event.currentTarget as HTMLInputElement;

    dispatch(setContact({ [name]: value }));
    validateField(name, value);
  };

  const handleInputBlur = (event: FormEvent) => {
    const { name } = event.currentTarget as HTMLInputElement;

    dispatch(setContactTouched({ [name]: true }));
  };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const { data, errors, touched } = contact;

    dispatch(
      setContactTouched({
        name: true,
        email: true,
        subject: true,
        message: true,
      }),
    );
    validateField('name', data.name);
    validateField('email', data.email);
    validateField('subject', data.subject);
    validateField('message', data.message);

    if (
      !errors.name &&
      touched.name &&
      !errors.email &&
      touched.email &&
      !errors.subject &&
      touched.subject &&
      !errors.message &&
      touched.message
    ) {
      setLoading(true);

      const token = await reCaptchaRef.current?.executeAsync();

      if (token) {
        sendEmail(token);
      } else {
        console.error('You must confirm you are not a robot.');
        setLoading(false);
      }
    }
  };

  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case 'name':
        contact.errors.name = value.length > 0 ? '' : t('validations.name');
        break;
      case 'email':
        contact.errors.email =
          value.length > 0
            ? value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
              ? ''
              : t('validations.emailInvalid')
            : t('validations.email');
        break;
      case 'subject':
        contact.errors.subject =
          value.length > 0 ? '' : t('validations.subject');
        break;
      case 'message':
        contact.errors.message =
          value.length > 0 ? '' : t('validations.message');
        break;
      default:
        break;
    }

    dispatch(setContactErrors(contact.errors));
  };

  const sendEmail = (token: string) => {
    const { data } = contact;

    const templateParams = {
      'g-recaptcha-response': token,
      name: data.name,
      email: data.email,
      subject: data.subject,
      message: data.message,
      ip: geolocation.data.ip,
      hostname: geolocation.data.hostname,
      org: geolocation.data.org,
      country: geolocation.data.country,
      region: geolocation.data.region,
      city: geolocation.data.city,
      postal: geolocation.data.postal,
      loc: geolocation.data.loc,
      timezone: geolocation.data.timezone,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID,
      )
      .then(
        () => {
          reCaptchaRef.current?.reset();
          setLoading(false);
          dispatch(resetContact());
          dispatch(setIsSentSuccessfully());
        },
        (error) => {
          console.error(error);
          setLoading(false);
          dispatch(setIsSentUnsuccessfully());
        },
      );
  };

  useEffect(() => {
    dispatch(resetContact());
  }, []);

  useEffect(() => {
    if (reCaptchaRef.current && reCaptchaRef.current.reset) {
      reCaptchaRef.current.reset();
    }

    return () => {
      if (reCaptchaRef.current) {
        reCaptchaRef.current = null;
      }
    };
  }, []);

  return (
    <section id="contact" className="pt-20">
      <div className="container">
        <h2>{t('title')}</h2>
      </div>
      <div className="py-14 md:py-28 bg-green-linear-gradient">
        <div className="container">
          <img
            src={require('src/client/assets/images/our-avatar.svg')}
            alt={t('ourAvatar')}
            className="mx-auto w-full max-w-[880px]"
            loading="lazy"
          />
          <div className="flex flex-col lg:flex-row gap-8">
            <FadeInAnimation
              direction="down"
              className="w-full lg:w-2/3 bg-gray"
            >
              <div className="px-8 md:px-12 py-12 md:py-16">
                <p className="mb-6 md:mb-10">{t('text')}</p>
                <form onSubmit={handleFormSubmit} className="flex flex-col">
                  <div className="block mb-6">
                    <label htmlFor="name" className="text-white">
                      {t('fields.name')} <sup className="text-red">*</sup>
                    </label>
                    <input
                      type="text"
                      className={`mt-1 block w-full shadow-2xl border-gray-300 focus:border-green-400 focus:ring focus:ring-green-400/50 ${
                        contact.errors.name && contact.touched.name
                          ? 'is-invalid'
                          : ''
                      }`}
                      name="name"
                      id="name"
                      value={contact.data.name}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                    {contact.errors.name && contact.touched.name && (
                      <div className="relative flex items-center mt-3 px-3 py-3 text-red-600 bg-red-200 border-l-4 border-red-600">
                        <UisExclamationCircle className="mr-3 text-red-600" />
                        {contact.errors.name}
                      </div>
                    )}
                  </div>
                  <div className="block mb-6">
                    <label htmlFor="email" className="text-white">
                      {t('fields.email')} <sup className="text-red">*</sup>
                    </label>
                    <input
                      type="text"
                      className={`mt-1 block w-full shadow-2xl border-gray-300 focus:border-green-400 focus:ring focus:ring-green-400/50 ${
                        contact.errors.email && contact.touched.email
                          ? 'is-invalid'
                          : ''
                      }`}
                      name="email"
                      id="email"
                      value={contact.data.email}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                    {contact.errors.email && contact.touched.email && (
                      <div className="relative flex items-center mt-3 px-3 py-3 text-red-600 bg-red-200 border-l-4 border-red-600">
                        <UisExclamationCircle className="mr-3 text-red-600" />
                        {contact.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="block mb-6">
                    <label htmlFor="subject" className="text-white">
                      {t('fields.subject')} <sup className="text-red">*</sup>
                    </label>
                    <select
                      className={`mt-1 block w-full shadow-2xl border-gray-300 focus:border-green-400 focus:ring focus:ring-green-400/50 ${
                        contact.errors.subject && contact.touched.subject
                          ? 'is-invalid'
                          : ''
                      }`}
                      name="subject"
                      id="subject"
                      value={contact.data.subject}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    >
                      <option value="" disabled>
                        {t('selectASubject')}
                      </option>
                      {subjects.map((subject: string, index: number) => (
                        <option key={index} value={t(`subjects.${subject}`)}>
                          {t(`subjects.${subject}`)}
                        </option>
                      ))}
                    </select>
                    {contact.errors.subject && contact.touched.subject && (
                      <div className="relative flex items-center mt-3 px-3 py-3 text-red-600 bg-red-200 border-l-4 border-red-600">
                        <UisExclamationCircle className="mr-3 text-red-600" />
                        {contact.errors.subject}
                      </div>
                    )}
                  </div>
                  <div className="block mb-8">
                    <label htmlFor="message" className="text-white">
                      {t('fields.message')} <sup className="text-red">*</sup>
                    </label>
                    <textarea
                      className={`mt-1 block w-full shadow-2xl border-gray-300 focus:border-green-400 focus:ring focus:ring-green-400/50 ${
                        contact.errors.message && contact.touched.message
                          ? 'is-invalid'
                          : ''
                      }`}
                      name="message"
                      id="message"
                      rows={5}
                      value={contact.data.message}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    ></textarea>
                    {contact.errors.message && contact.touched.message && (
                      <div className="relative flex items-center mt-3 px-3 py-3 text-red-600 bg-red-200 border-l-4 border-red-600">
                        <UisExclamationCircle className="mr-3 text-red-600" />
                        {contact.errors.message}
                      </div>
                    )}
                  </div>
                  <div className="block">
                    {contact.isSentSuccessfully ? (
                      <span className="relative flex items-center px-3 py-3 text-green-600 bg-green-200/30 border-l-4 border-green-600">
                        <UisCheckCircle className="flex-none mr-3 text-green-600" />
                        {t('notifications.success')}
                      </span>
                    ) : contact.isSentUnsuccessfully ? (
                      <span className="relative flex items-center px-3 py-3 text-red-600 bg-red-200 border-l-4 border-red-600">
                        <UisExclamationCircle className="flex-none mr-3 text-red-600" />
                        {t('notifications.failure')}
                      </span>
                    ) : (
                      <button type="submit" className="btn btn-green">
                        {loading && (
                          <svg
                            className="inline-block mb-0.5 -ml-1 mr-3 h-5 w-5 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                        {t('buttonText')}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </FadeInAnimation>
            <FadeInAnimation
              direction="left"
              className="hidden lg:block w-full lg:w-1/3 bg-gray"
            >
              <div className="flex items-center px-8 md:px-12 py-12 md:py-16 h-full">
                <img
                  src={require('src/client/assets/images/contact.svg')}
                  alt={t('contactIllustration')}
                  className="mx-auto max-h-[300px] lg:max-h-[590px]"
                  loading="lazy"
                />
              </div>
            </FadeInAnimation>
          </div>
        </div>
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
          size="invisible"
          theme="dark"
        />
      </div>
    </section>
  );
};

export default Contact;
