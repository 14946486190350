import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'react-device-detect';
import { Disclosure, Transition } from '@headlessui/react';
import { UilAngleDown } from '@iconscout/react-unicons';

import { VideoProps } from 'src/client/interfaces/video.interface';

import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';
import VideoPlayer from 'src/client/components/VideoPlayer/VideoPlayer';
import PlayIcon from 'src/client/components/PlayIcon/PlayIcon';

const videos: VideoProps[] = [
  {
    src:
      'sign-language-why-is-sign-language-important-for-the-deaf-community' +
      (isIOS ? '.mp4' : '.webm'),
  },
  {
    src:
      'sign-language-what-are-the-similarities-between-spoken-and-sign-languages' +
      (isIOS ? '.mp4' : '.webm'),
  },
  {
    src:
      'sign-language-why-is-sign-language-a-natural-language' +
      (isIOS ? '.mp4' : '.webm'),
  },
  {
    src:
      'sign-language-how-do-deaf-people-from-different-countries-communicate-with-each-other' +
      (isIOS ? '.mp4' : '.webm'),
  },
  {
    src:
      'sign-language-i-am-sorry-but-i-do-not-know-hungarian-sign-language' +
      (isIOS ? '.mp4' : '.webm'),
  },
  {
    src:
      'sign-language-can-you-tell-me-some-interesting-facts-about-sign-language' +
      (isIOS ? '.mp4' : '.webm'),
  },
];

const FAQs: React.FC = () => {
  const { t } = useTranslation('sign-language');

  const [openVideo, setOpenVideo] = useState<boolean>(false);
  const [videoSrc, setVideoSrc] = useState<string>('');

  return (
    <>
      <VideoPlayer
        open={openVideo}
        onClick={() => setOpenVideo(false)}
        src={videoSrc}
      />
      <section id="faqs" className="pb-20">
        <div className="container">
          <h2>{t('faqsTitle')}</h2>
          {t('faqs', { returnObjects: true }).map((faq: any, index: number) => (
            <FadeInAnimation key={index} direction="down" className="mb-8">
              <Disclosure as="div">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex flex-row items-center w-full px-4 py-8 bg-gray custom-shadow-soft">
                      <UilAngleDown
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } mr-2 md:mr-4 h-10 w-10 text-green duration-300`}
                      />
                      <h5 className="flex-1">{faq.question}</h5>
                    </Disclosure.Button>

                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel
                        as="div"
                        className="flex flex-col md:flex-row pl-12 md:pl-20 pr-8 py-8 bg-gray custom-shadow-soft"
                      >
                        <ul className="flex-1 list-disc">
                          {faq.answers.map((answer: string, i: number) => (
                            <li key={i} className="text-white">
                              {answer}
                            </li>
                          ))}
                        </ul>
                        {videos[index] && (
                          <div
                            className="relative flex flex-col justify-end items-end pl-4 pb-2 text-white text-right whitespace-pre-line"
                            onClick={() => {
                              setOpenVideo(true);
                              setVideoSrc(videos[index].src);
                            }}
                          >
                            <PlayIcon className="mb-2" />
                            {t('iWantToSeeItInSignLanguage')}
                          </div>
                        )}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </FadeInAnimation>
          ))}
        </div>
      </section>
    </>
  );
};

export default FAQs;
