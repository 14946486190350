import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'react-device-detect';

import { VideoProps } from 'src/client/interfaces/video.interface';

import { history } from 'src/client/store/configureStore';

import { scrollToElement, scrollToTop } from 'src/client/helpers/globals';

import Contact from 'src/client/components/Contact/Contact';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';
import PageTitle from 'src/client/components/PageTitle/PageTitle';
import PlayIcon from 'src/client/components/PlayIcon/PlayIcon';
import VideoPlayer from 'src/client/components/VideoPlayer/VideoPlayer';

import './ImpactPledge.scss';

const elementIds: string[] = [
  'our-mission',
  'challenge',
  'we-build-on-our-strengths',
  'social-inclusion',
  'trainee-education',
];

const videos: VideoProps[] = [
  {
    name: 'Our mission',
    thumbnail: 'impact-pledge-our-mission.webp',
    src: 'impact-pledge-our-mission' + (isIOS ? '.mp4' : '.webm'),
  },
  {
    name: 'Challenge',
    thumbnail: 'impact-pledge-challenge.webp',
    src: 'impact-pledge-challenge' + (isIOS ? '.mp4' : '.webm'),
  },
  {
    name: 'We build on strengths',
    thumbnail: 'impact-pledge-we-build-on-strengths.webp',
    src: 'impact-pledge-we-build-on-strengths' + (isIOS ? '.mp4' : '.webm'),
  },
  {
    name: 'Social inclusion',
    thumbnail: 'impact-pledge-social-inclusion.webp',
    src: 'impact-pledge-social-inclusion' + (isIOS ? '.mp4' : '.webm'),
  },
  {
    name: 'Trainee education',
    thumbnail: 'impact-pledge-trainee-education.webp',
    src: 'impact-pledge-trainee-education' + (isIOS ? '.mp4' : '.webm'),
  },
];

const ImpactPledge: React.FC = () => {
  const { t } = useTranslation('impact-pledge');

  const [openVideo, setOpenVideo] = useState<boolean>(false);
  const [videoSrc, setVideoSrc] = useState<string>('');

  const { hash } = history.location;

  useEffect(() => {
    scrollToTop({});

    // Delay due to images loading
    let timer: string | number | NodeJS.Timeout | undefined = undefined;

    if (hash) {
      timer = setTimeout(() => {
        scrollToElement({ elementId: hash.substring(1), top: 30 });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="impact-pledge" className="pt-32">
      <PageTitle title={t('title')} />
      <VideoPlayer
        open={openVideo}
        onClick={() => setOpenVideo(false)}
        src={videoSrc}
      />
      <section className="pb-20 bg-both-side">
        <div className="container">
          <h1 className="page-title-hidden">{t('title')}</h1>
          <h2>{t('subtitle')}</h2>
          {t('contents', { returnObjects: true }).map(
            (content: any, index: number) => (
              <div
                key={index}
                className="flex flex-col xl:flex-row items-end pt-[300px] xl:pt-0 xl:h-[650px] box"
                id={elementIds[index]}
              >
                <div className="mb-0 flex flex-col xl:flex-row items-end card">
                  {videos[index] && (
                    <div
                      className={`${
                        index % 2 ? 'xl:order-1' : 'xl:order-2'
                      } relative mx-auto mb-8 xl:m-0 w-full md:w-[364px] h-[125px] md:h-[250px] cursor-pointer duration-300 hover:scale-105`}
                      onClick={() => {
                        setOpenVideo(true);
                        setVideoSrc(videos[index].src);
                      }}
                    >
                      <LazyLoadImage
                        src={
                          videos[index].thumbnail
                            ? require(
                                'src/client/assets/images/' +
                                  videos[index].thumbnail,
                              )
                            : ''
                        }
                        alt={videos[index].name || ''}
                        className="absolute bottom-0 w-[364px] xl:h-[500px] object-cover custom-shadow-soft"
                      />
                      <div className="absolute right-[65px] bottom-[30px] btn text-white bg-green-400">
                        {t('play')}
                        <PlayIcon className="absolute bottom-1/2 translate-y-1/2 -right-[45px]" />
                      </div>
                    </div>
                  )}
                  <div
                    className={`${
                      index % 2 ? 'xl:order-2 xl:pl-12' : 'xl:order-1 xl:pr-8'
                    } flex-1`}
                  >
                    <h3>{content.title}</h3>
                    <p>{content.text}</p>
                    <ul className="mt-2 pl-8 text-white text-base md:text-lg list-disc">
                      {content.lists &&
                        content.lists.map((list: string, index: number) => (
                          <li key={index}>{list}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </section>
      <Contact />
    </div>
  );
};

export default ImpactPledge;
