import React from 'react';

import { StacksProps } from 'src/client/interfaces/stack.interface';

import { ImageProps } from 'src/client/types/image';

import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

const Stacks: React.FC<StacksProps> = ({ title = '', stacks }: StacksProps) => (
  <section id="stack">
    <div className="container">
      <h2>{title}</h2>
      <div className="flex flex-wrap justify-center items-center gap-8 lg:gap-12">
        {stacks.map((image: ImageProps, index: number) => (
          <LazyLoadImage
            key={index}
            src={
              image.src ? require('src/client/assets/logos/' + image.src) : ''
            }
            alt={image.title}
            title={image.title}
            className="w-full max-w-[50px] lg:max-w-[80px]"
          />
        ))}
      </div>
    </div>
  </section>
);

export default Stacks;
