import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProps } from 'src/client/interfaces/theme.interface';

import { setTheme } from 'src/client/store/theme/actions';

import { Dispatch, RootState } from 'src/client/index';

type ThemeContextProps = {
  newTheme: string;
  setNewTheme: (theme: string) => void;
};

const themeContextDefaultValue: ThemeContextProps = {
  newTheme: '',
  setNewTheme: () => {},
};

export const ThemeContext = createContext<ThemeContextProps>(
  themeContextDefaultValue,
);

const ThemeProvider: React.FC<ThemeProps> = ({ children }: ThemeProps) => {
  const dispatch: Dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.theme.theme);

  const setNewTheme = (theme: string) => {
    const root = window.document.documentElement;
    const isDark = theme === 'dark';

    root.classList.remove(isDark ? 'light' : 'dark');
    root.classList.add(theme);

    dispatch(setTheme(theme));
  };

  useEffect(() => {
    setNewTheme(theme);
  }, [dispatch]);

  return (
    <ThemeContext.Provider value={{ newTheme: theme, setNewTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
