import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  ReferenceProps,
  ReferencesProps,
} from 'src/client/interfaces/reference.interface';

import { scrollToElement } from 'src/client/helpers/globals';

import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';

import Reference from './Reference/Reference';

import './References.scss';

const References: React.FC<ReferencesProps> = ({
  references,
}: ReferencesProps) => {
  const { t } = useTranslation('global');

  return (
    <section id="references" className="py-20">
      <div className="container">
        <h2>{t('ourReferences')}</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {references.map((reference: ReferenceProps, index: number) => (
            <FadeInAnimation
              key={index}
              direction="down"
              className={`${
                index === 0 ? 'col-span-full' : ''
              } [&:not(:first-child)]:h-[120px] [&:not(:first-child)]:md:h-[150px] [&:not(:first-child)]:lg:h-[270px]`}
            >
              <Reference
                src={reference.src}
                title={reference.title}
                logo={reference.logo}
              />
            </FadeInAnimation>
          ))}
          <FadeInAnimation
            direction="down"
            className="h-[120px] md:h-[150px] lg:h-[270px]"
          >
            <div className="flex flex-col justify-center items-center w-full h-full text-white text-base lg:text-xl bg-green text-center rounded-3xl whitespace-pre-line">
              {t('hereCouldBeOurWorkTogether')}
              <Link
                to="#contact"
                className="mt-2 lg:mt-4 px-4 md:px-8 py-2 text-green-400 bg-white opacity-80 custom-shadow-soft"
                onClick={() => {
                  scrollToElement({ elementId: 'contact', top: 0 });
                }}
              >
                {t('contactUs')}
              </Link>
            </div>
          </FadeInAnimation>
        </div>
      </div>
    </section>
  );
};

export default References;
