import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRouterState } from '@lagunovsky/redux-react-router';
import { useTranslation } from 'react-i18next';

import { RootState } from 'src/client';

import { IntroProps } from 'src/client/interfaces/intro.interface';

import { scrollToElement } from 'src/client/helpers/globals';

import LanguageSwitcher from 'src/client/components/LanguageSwitcher/LanguageSwitcher';
import LazyLoadImage from 'src/client/components/LazyLoadImage/LazyLoadImage';

import './LandingIntro.scss';

const LandingIntro: React.FC<IntroProps> = ({
  title,
  paragraph = '',
  backgroundImageSrc,
  illustrationImageSrc = '',
}: IntroProps) => {
  const { t } = useTranslation(['global', 'navigation']);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const router: ReduxRouterState = useSelector(
    (state: RootState) => state.router,
  );

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <section id="landing-intro">
      <div className="relative h-[500px] md:h-[800px] overflow-hidden">
        {backgroundImageSrc && (
          <LazyLoadImage
            src={require('src/client/assets/images/' + backgroundImageSrc)}
            alt={t('backgroundImage', { ns: 'global' })}
            className="absolute top-0 left-0 right-0 w-full h-full object-cover object-[40%_center]"
          />
        )}
        <div className="container relative h-full">
          {illustrationImageSrc && (
            <LazyLoadImage
              src={require('src/client/assets/images/' + illustrationImageSrc)}
              alt={t('illustration', { ns: 'global' })}
              className="hidden md:block absolute right-0 bottom-0 md:max-h-[500px] xl:max-h-full"
            />
          )}
          <div className="relative pt-8 pb-16">
            <div
              className={`fixed lg:relative menu ${isMenuOpen ? 'show' : ''}`}
            >
              <button
                type="button"
                className="lg:hidden absolute top-8 right-8 p-2 focus:outline-none"
                aria-controls="menu-toggler"
                aria-expanded="false"
                aria-label="Mobile toggle menu"
                onClick={handleMenuToggle}
              >
                <div className="relative w-8 h-6 rotate-0 cursor-pointer menu-icon open">
                  <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                  <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                  <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                  <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                </div>
              </button>
              <nav className="flex flex-col lg:flex-row justify-center items-center mb-8 px-8 py-2 w-full lg:max-w-max h-full bg-white lg:bg-white/70 text-center lg:rounded-[50px] whitespace-nowrap lg:whitespace-normal lg:custom-shadow-soft">
                <Link
                  to="/#services"
                  className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                  onClick={() => {
                    handleMenuClose();
                    scrollToElement({ elementId: 'services', top: 120 });
                  }}
                >
                  {t('services', { ns: 'navigation' })}
                </Link>
                <NavLink
                  to="/impact-pledge"
                  className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                  onClick={handleMenuClose}
                >
                  {t('impactPledge', { ns: 'navigation' })}
                </NavLink>
                <NavLink
                  to="/sign-language"
                  className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                  onClick={handleMenuClose}
                >
                  {t('signLanguage', { ns: 'navigation' })}
                </NavLink>
                <NavLink
                  to="/about"
                  className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                  onClick={handleMenuClose}
                >
                  {t('about', { ns: 'navigation' })}
                </NavLink>
                <NavLink
                  to="/blog"
                  className="mx-2 px-2 py-3 lg:py-2 text-black text-lg leading-7 lg:text-sm font-bold"
                  onClick={handleMenuClose}
                >
                  {t('blog', { ns: 'navigation' })}
                </NavLink>
                <Link
                  to="#contact"
                  className="lg:mx-4 my-2 lg:my-0 text-lg lg:text-sm btn btn-green"
                  onClick={() => {
                    handleMenuClose();
                    scrollToElement({ elementId: 'contact', top: 0 });
                  }}
                >
                  {t('contact', { ns: 'navigation' })}
                </Link>
                <div className="hidden lg:block">
                  <LanguageSwitcher />
                </div>
              </nav>
            </div>
            <div className="flex flex-row justify-between items-center mb-12">
              <NavLink to="/">
                <LazyLoadImage
                  src={require('src/client/assets/logos/sc-logo-white.svg')}
                  alt={t('logo', { ns: 'global' })}
                  className="h-16 logo"
                />
              </NavLink>
              <div className="flex flex-row items-center">
                <div className="lg:hidden">
                  <LanguageSwitcher />
                </div>
                <button
                  type="button"
                  className="lg:hidden p-2 focus:outline-none"
                  aria-controls="menu-toggler"
                  aria-expanded="false"
                  aria-label="Mobile toggle menu"
                  onClick={handleMenuToggle}
                >
                  <div className="relative w-8 h-6 rotate-0 cursor-pointer menu-icon">
                    <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                    <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                    <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                    <span className="block absolute left-0 w-full h-0.5 bg-white opacity-100"></span>
                  </div>
                </button>
              </div>
            </div>
            {router.location.pathname ===
              '/digital-knowledge-dissemination' && (
              <LazyLoadImage
                src={require('src/client/assets/images/erasmus.webp')}
                alt="Erasmus+"
                className="mb-4 max-w-[200px] md:max-w-[300px]"
              />
            )}
            <h1 className="mb-8 uppercase whitespace-pre-line">{title}</h1>
            {paragraph && (
              <p className="text-lg leading-7 md:text-xl md:leading-9 whitespace-pre-line">
                {paragraph}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingIntro;
