import { ThemeAction, ThemeState } from './types';
import { SET_THEME } from './constants';

const initialState: ThemeState = {
  theme: 'dark',
};

export default (
  state: ThemeState = initialState,
  action: ThemeAction,
): ThemeState => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};
