import { PostProps } from 'src/client/interfaces/post.interface';

import { PostAction } from './types';
import { RESET_POST, SET_POST, SET_POST_ERROR } from './constants';

export const setPost = (payload: PostProps): PostAction => {
  return {
    type: SET_POST,
    payload,
  };
};

export const setPostError = (payload: string): PostAction => {
  return {
    type: SET_POST_ERROR,
    payload,
  };
};

export const resetPost = (): PostAction => {
  return {
    type: RESET_POST,
  };
};
